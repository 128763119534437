import React, { useMemo } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { useAuth } from '../../../app/context/auth';
import { useKegs } from '../../../app/context/kegs';
import SimpleLoader from '../../../app/layout/SimpleLoader';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict/index';
import { Link } from 'react-router-dom';
import { fromUnixTime } from 'date-fns';
const MostFilledKegLeaderBoard = () => {
	const { claims } = useAuth();
	const { companyKegs: compKegs } = useKegs();
	const companyKegs = useMemo(() => {
		if (compKegs) {
			return compKegs
				?.filter((keg) => keg?.fillCount)
				.sort((a, b) => {
					if (a.fillCount < b.fillCount) {
						return 1;
					}
					if (a.fillCount > b.fillCount) {
						return -1;
					}
					return 0;
				});
		}
	}, [compKegs]);

	return claims?.companyLink ? (
		<div className="widget margin-bottom">
			<div className="widget-table-header">
				<h2>Most Filled Kegs</h2>
				<Button size="mini" as={Link} to={`/most-filled`}>
					View all
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
			{companyKegs ? (
				companyKegs.length !== 0 ? (
					<Table
						basic="very"
						compact="very"
						celled
						collapsing
						className="brands-table"
					>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell textAlign="center">
									Number of Fills
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Volume Filled
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Age of Keg Since Onboarding
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{companyKegs && (
								<>
									<Table.Row>
										<Table.Cell textAlign="center">
											{companyKegs[0]?.id}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{companyKegs[0]?.fillCount
												? companyKegs[0]?.fillCount
												: '-'}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{companyKegs[0]?.volume && companyKegs[0]?.fillCount
												? Number(companyKegs[0]?.volume) *
												  companyKegs[0]?.fillCount
												: '-'}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{/* {new Date(
											companyKegs[0]?.dateCreated.toDate()
										).toLocaleDateString()} */}
											{companyKegs[0]?.dateOnboarded
												? distanceInWordsStrict(
														companyKegs[0]?.dateOnboarded?.toDate(),
														new Date()
												  )
												: companyKegs[0]?.dateCreated
												? distanceInWordsStrict(
														companyKegs[0]?.dateCreated?.toDate(),
														new Date()
												  )
												: '-'}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell textAlign="center">
											{companyKegs[1]?.id}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{companyKegs[1]?.fillCount
												? companyKegs[1]?.fillCount
												: '-'}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{companyKegs[1]?.volume && companyKegs[1]?.fillCount
												? Number(companyKegs[1]?.volume) *
												  companyKegs[1]?.fillCount
												: '-'}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{/* {new Date(
											companyKegs[0]?.dateCreated.toDate()
										).toLocaleDateString()} */}
											{companyKegs[1]?.dateOnboarded
												? distanceInWordsStrict(
														companyKegs[1]?.dateOnboarded?.toDate(),
														new Date()
												  )
												: companyKegs[1]?.dateCreated
												? distanceInWordsStrict(
														companyKegs[1]?.dateCreated?.toDate(),
														new Date()
												  )
												: '-'}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell textAlign="center">
											{companyKegs[2]?.id}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{companyKegs[2]?.fillCount
												? companyKegs[2]?.fillCount
												: '-'}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{companyKegs[2]?.volume && companyKegs[2]?.fillCount
												? Number(companyKegs[2]?.volume) *
												  companyKegs[2]?.fillCount
												: '-'}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{/* {new Date(
											companyKegs[0]?.dateCreated.toDate()
										).toLocaleDateString()} */}
											{companyKegs[2]?.dateOnboarded
												? distanceInWordsStrict(
														companyKegs[2]?.dateOnboarded?.toDate(),
														new Date()
												  )
												: companyKegs[1]?.dateCreated
												? distanceInWordsStrict(
														companyKegs[2]?.dateCreated?.toDate(),
														new Date()
												  )
												: '-'}
										</Table.Cell>
									</Table.Row>
								</>
							)}
						</Table.Body>
					</Table>
				) : (
					<div className="no-records-found-illustration-wrapper">
						<img src="/assets/illustrations/empty.svg" alt="Report Empty" />
						<p>No Kegs Found...</p>
					</div>
				)
			) : (
				<div className="no-records-found-illustration-wrapper">
					<SimpleLoader color="grey" />
					<p>Fetching kegs...</p>
				</div>
			)}
		</div>
	) : null;
};

export default MostFilledKegLeaderBoard;
