import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { formatBatchData } from './formatting';
import { useKegs } from '../kegs';

const BatchesContext = createContext({});

export const BatchesProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const [batches, setBatches] = useState();
	const { companyKegs, partnerKegs } = useKegs();
	function dateIsValid(date) {
		return !Number.isNaN(new Date(date).getTime());
	}
	useEffect(() => {
		return claims?.companyLink
			? firebase.batches(getObjFromLink(claims.companyLink).id).onSnapshot(
					(snap) =>
						setBatches(
							transformCollectionSnap(snap)
								.filter((batch) => batch.bestBefore !== 'Invalid Date')
								.filter((batch) => batch.bestBefore !== undefined)
								.map((batch) => ({
									...batch,
									bestBefore: new Date(
										dateIsValid(batch.bestBefore)
											? batch.bestBefore
											: batch.bestBefore.toDate()
									),
									dateCreated: new Date(
										dateIsValid(batch.dateCreated)
											? batch.dateCreated
											: batch.dateCreated.toDate()
									)
								}))

								.sort((a, b) => {
									const firstDate = a.dateCreated;
									const secDate = b.dateCreated;
									if (firstDate > secDate) {
										return 1;
									}
									if (firstDate < secDate) {
										return -1;
									}
									return 0;
								})
						),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);

	const createBatch = async (batch) => {
		const newBatch = formatBatchData(batch, claims.companyLink, true);

		return await firebase
			.batches(getObjFromLink(claims.companyLink).id)
			.add(newBatch);
	};
	const updateBatch = async (batch, id) => {
		const updatedBatch = formatBatchData(batch, claims.companyLink);
		let fsBatch = firebase.db.batch();
		// Update Company Profile
		let batchDocRef = firebase
			.batches(getObjFromLink(claims.companyLink).id)
			.doc(id);
		await fsBatch.update(batchDocRef, updatedBatch);
		// New Batch Data for linked assets

		if (companyKegs && partnerKegs) {
			const newBatchData = {
				batch: {
					bestBefore: updatedBatch.bestBefore,
					batchNo: updatedBatch.batchNo
				}
			};

			const kegs = [...companyKegs, ...partnerKegs];
			const brandedKegs = kegs.filter((k) => k.batch?.id === id);

			brandedKegs.forEach(
				async (k) =>
					await fsBatch.update(
						firebase.db.collection('kegs').doc(k.id),
						newBatchData
					)
			);
		}

		// end batch
		await fsBatch.commit();
	};

	const archiveBatch = async (isArchive, id) => {
		return await firebase
			.batches(getObjFromLink(claims.companyLink).id)
			.doc(id)
			.update({
				isArchive: !isArchive,
				dateUpdated: new Date()
			});
	};

	return (
		<BatchesContext.Provider
			value={{ batches, createBatch, updateBatch, archiveBatch }}
		>
			{children}
		</BatchesContext.Provider>
	);
};

export const BatchesConsumer = BatchesContext.Consumer;

export const useBatches = () => useContext(BatchesContext);
