import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { useKegs } from '../kegs';
import { formatLocationData } from './formatting';
import { faCommentsDollar } from '@fortawesome/pro-regular-svg-icons';

const LocationsContext = createContext({});

export const LocationsProvider = ({ children }) => {
	const firebase = useFirebase();
	const [locations, setLocations] = useState();
	const { companyKegs, partnerKegs } = useKegs();
	const { claims } = useAuth();
	useEffect(() => {
		return claims?.companyLink
			? firebase.locations(getObjFromLink(claims?.companyLink).id).onSnapshot(
					(snap) =>
						setLocations(
							transformCollectionSnap(snap).filter(
								(location) => !location.isArchive
							)
						),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);
	useEffect(() => {
		console.log(locations, 'locations');
	}, [locations]);
	const createLocation = async (location) => {
		const newLocation = formatLocationData(location, claims.companyLink, true);

		return await firebase
			.locations(getObjFromLink(claims.companyLink).id)
			.add(newLocation);
	};
	const updateLocation = async (location, id) => {
		const updatedLocation = formatLocationData(location, claims.companyLink);
		return await firebase
			.locations(getObjFromLink(claims.companyLink).id)
			.doc(id)
			.update(updatedLocation);
	};

	const archiveLocation = async (isArchive, id) => {
		return await firebase
			.locations(getObjFromLink(claims.companyLink).id)
			.doc(id)
			.update({
				isArchive: !isArchive,
				dateUpdated: new Date()
			});
	};

	const locationStats = useMemo(() => {
		if (locations && companyKegs && partnerKegs) {
			const kegs = [
				...companyKegs
					.filter((k) => !k.isArchive)
					.filter((keg) => !keg.partnerLink),
				...partnerKegs.filter((k) => !k.isArchive)
			];

			// Kegs with Locations
			const kegsInWarehouse = kegs.filter(
				(keg) =>
					keg.locationStatus === 'in_warehouse__own' ||
					keg.locationStatus === 'in_warehouse__partner'
			);
			// Kegs with Locations & Empty
			const emptyKegs = kegsInWarehouse.filter(
				(keg) => !keg.brandLink && !keg.isFilled
			);
			// Kegs with Locations & Filled
			const filledKegs = kegsInWarehouse.filter(
				(keg) => keg.brandLink && keg.isFilled
			);
			const volumeKegs = filledKegs
				.filter((keg) => keg.volume)
				.reduce((acc, curr) => {
					return acc + +curr.volume;
				}, 0);

			// LOCATIONS

			const locationStockStats = locations
				.filter((l) => !l.isArchive)
				.map((location) => {
					const thisLocationsKegs = kegsInWarehouse.filter(
						(keg) => keg.locationObj?.id === location.id
					);
					const thisEmptyKegs = thisLocationsKegs.filter(
						(keg) => !keg.isFilled
					);
					const thisFilledKegs = thisLocationsKegs.filter(
						(keg) => keg.isFilled
					);
					const thisVolumekegs = thisFilledKegs
						.filter((keg) => keg.volume)
						.reduce((acc, curr) => {
							return acc + +curr.volume;
						}, 0);
					const locationData = {
						name: location.name,
						countEmpty: thisEmptyKegs.length,
						empty: thisEmptyKegs.map((k) => k.id),
						countFilled: thisFilledKegs.length,
						filled: thisFilledKegs.map((k) => k.id),
						// countVolumeKegs: thisVolumekegs.length,
						volume: thisVolumekegs,
						countInWarehouse: thisLocationsKegs.length,
						id: location.id
					};
					return locationData;
				});
			locationStockStats.sort((a, b) => (a.name > b.name ? 1 : -1));

			// STATS OBJECT
			const locationsStats = {};
			locationsStats.countInWarehouse = kegsInWarehouse.length;
			locationsStats.countEmpty = emptyKegs.length;
			locationsStats.countFilled = filledKegs.length;
			locationsStats.countVolumeKegs = volumeKegs;
			locationsStats.locationTotals = locationStockStats;
			return locationsStats;
		}
	}, [locations, companyKegs, partnerKegs]);

	const partnerLocationStats = useMemo(() => {
		const kegs = partnerKegs ? partnerKegs?.filter((k) => !k.isArchive) : [];
		// Kegs with Locations
		const partnerKegsInWarehouse = kegs.filter(
			(keg) =>
				keg.locationStatus === 'in_warehouse__partner' ||
				keg.locationStatus === 'dispatched__client'
		);
		const partnerKegsInClients = kegs?.filter(
			(keg) => keg.locationStatus === 'dispatched__client'
		);
		// Kegs with Locations & Empty
		const emptyKegs = partnerKegsInWarehouse
			.filter((keg) => !keg.isFilled)
			.filter((keg) => keg.locationStatus === 'in_warehouse__partner');
		// Kegs with Locations & Filled
		const filledKegs = partnerKegsInWarehouse
			.filter((keg) => keg.isFilled)
			.filter((keg) => keg.locationStatus === 'in_warehouse__partner');
		// Kegs dispatched to clients

		// console.log(withClients, 'with clients');
		const locationIds = [];
		const locations = [];
		partnerKegsInWarehouse.forEach((k) => {
			if (k.locationObj) {
				if (!locationIds.includes(k.locationObj?.id)) {
					locationIds.push(k.locationObj?.id);
					locations.push(k.locationObj);
				}
			}
		});

		let locationStockStats = [];
		locations.forEach((location) => {
			const thisLocationsKegs = partnerKegsInWarehouse.filter(
				(keg) => keg.locationObj && keg.locationObj?.id === location.id
			);
			const thisEmptyKegs = thisLocationsKegs
				.filter((keg) => !keg.isFilled)
				.filter((keg) => keg.locationStatus === 'in_warehouse__partner');
			const thisFilledKegs = thisLocationsKegs
				.filter((keg) => keg.isFilled)
				.filter((keg) => keg.locationStatus === 'in_warehouse__partner');
			const thisWithClientsKegs = thisLocationsKegs.filter(
				(keg) => keg.locationStatus === 'dispatched__client'
			);

			const locationData = {
				name: location.name,
				countEmpty: thisEmptyKegs.length,
				empty: thisEmptyKegs.map((k) => k.id),
				countFilled: thisFilledKegs.length,
				filled: thisFilledKegs.map((k) => k.id),
				countWithClients: thisWithClientsKegs.length,
				withClients: thisWithClientsKegs.map((k) => k.id),
				countInWarehouse: thisLocationsKegs.filter(
					(keg) => keg.locationStatus === 'in_warehouse__partner'
				).length,
				// countInClients: thisLocationsKegs.length,
				id: location.id
			};
			locationStockStats = [...locationStockStats, locationData];
		});
		locationStockStats.sort((a, b) => (a.name > b.name ? 1 : -1));

		// STATS OBJECT
		const locationsStats = {};
		locationsStats.countInWarehouse = partnerKegsInWarehouse.length;
		locationsStats.countEmpty = emptyKegs.length;
		locationsStats.countFilled = filledKegs.length;
		locationsStats.withClients = partnerKegsInClients.length;
		locationsStats.locationTotals = locationStockStats;
		return locationsStats;
	}, [partnerKegs]);

	const atPartnerLocationStats = useMemo(() => {
		const kegs = companyKegs ? companyKegs?.filter((k) => !k.isArchive) : [];

		const atPartnerKegs = kegs.filter((keg) => keg.partnerLink);

		const atPartnerKegsInWarehouse = atPartnerKegs?.filter(
			(keg) =>
				keg.locationStatus === 'in_warehouse__partner' ||
				keg.locationStatus === 'dispatched__client'
		);
		const atPartnerKegsInClients = atPartnerKegs?.filter(
			(keg) => keg.locationStatus === 'dispatched__client'
		);

		const inTransitKegs = atPartnerKegs?.filter(
			(keg) => keg.locationStatus === 'dispatched__partner'
		);

		const allAtPartnerKegs = [...atPartnerKegsInWarehouse, ...inTransitKegs];
		console.log(
			allAtPartnerKegs
				?.filter((keg) => keg.isFilled)
				.filter((keg) => keg.locationStatus !== 'dispatched__client'),
			'fill'
		);
		const emptyKegs = allAtPartnerKegs
			?.filter((keg) => !keg.isFilled)
			.filter((keg) => keg.locationStatus !== 'dispatched__client');
		const filledKegs = allAtPartnerKegs
			?.filter((keg) => keg.isFilled)
			.filter((keg) => keg.locationStatus !== 'dispatched__client')
			.filter((keg) => keg.locationStatus !== 'dispatched__partner');
		const withClientsKegs = allAtPartnerKegs?.filter(
			(keg) => keg.locationStatus === 'dispatched__client'
		);
		const locationIds = [];
		const locations = [];
		atPartnerKegsInWarehouse.forEach((k) => {
			if (k.locationObj) {
				if (!locationIds.includes(k.locationObj?.id)) {
					locationIds.push(k.locationObj?.id);
					locations.push(k.locationObj);
				}
			}
		});

		const inTransitPartnerIds = [];
		const inTransitPartners = [];

		inTransitKegs.forEach((k) => {
			if (!inTransitPartnerIds.includes(k.partnerObj?.id)) {
				inTransitPartnerIds.push(k.partnerObj?.id);
				inTransitPartners.push(k.partnerObj);
			}
		});

		let locationStockStats = [];
		locations.forEach((location) => {
			const thisLocationsKegs = atPartnerKegsInWarehouse.filter(
				(keg) => keg.locationObj && keg.locationObj?.id === location.id
			);
			const thisEmptyKegs = thisLocationsKegs
				.filter((keg) => !keg.isFilled)
				.filter((keg) => keg.locationStatus !== 'dispatched__client');
			const thisFilledKegs = thisLocationsKegs
				.filter((keg) => keg.isFilled)
				.filter((keg) => keg.locationStatus !== 'dispatched__client');
			const thisWithClientsKegs = thisLocationsKegs.filter(
				(keg) => keg.locationStatus === 'dispatched__client'
			);

			const locationData = {
				name: location.name,
				partner:
					thisLocationsKegs?.length > 0
						? thisLocationsKegs[0]?.partnerObj?.name
						: '-',
				id: location.id,
				countEmpty: thisEmptyKegs.length,
				empty: thisEmptyKegs.map((k) => k.id),
				countFilled: thisFilledKegs.length,
				filled: thisFilledKegs.map((k) => k.id),
				countWithClients: thisWithClientsKegs.length,
				withClients: thisWithClientsKegs.map((k) => k.id),
				countInWarehouse: thisLocationsKegs.length
			};
			locationStockStats = [...locationStockStats, locationData];
		});

		inTransitPartners
			.filter((p) => p !== undefined && p !== null)
			.forEach((p) => {
				const thisLocationsKegs = inTransitKegs.filter(
					(k) => k.partnerObj?.id === p.id
				);

				const thisEmptyKegs = thisLocationsKegs
					.filter((keg) => !keg.isFilled)
					.filter((keg) => keg.locationStatus !== 'dispatched__client');

				const thisFilledKegs = thisLocationsKegs
					.filter((keg) => keg.isFilled)
					.filter((keg) => keg.locationStatus !== 'dispatched__client');

				const thisWithClientsKegs = thisLocationsKegs
					.filter((keg) => keg.isFilled)
					.filter((keg) => keg.locationStatus === 'dispatched__client');

				const locationData = {
					name: 'In Transit',
					partner: p.name,
					id: p.id,
					countEmpty: thisEmptyKegs.length,
					empty: thisEmptyKegs.map((k) => k.id),
					countFilled: thisFilledKegs.length,
					filled: thisFilledKegs.map((k) => k.id),
					countInWarehouse: thisLocationsKegs.length
				};
				locationStockStats = [...locationStockStats, locationData];
			});

		locationStockStats.sort((a, b) => (a.name > b.name ? 1 : -1));

		// STATS OBJECT
		const locationsStats = {};
		locationsStats.countInWarehouse = allAtPartnerKegs?.length;
		locationsStats.countEmpty = emptyKegs.length;
		locationsStats.countFilled = filledKegs.length;
		locationsStats.withClients = withClientsKegs.length;
		locationsStats.locationTotals = locationStockStats;
		// locationsStats.withClients = atPartnerKegsInClients.length;
		return locationsStats;
	}, [companyKegs]);

	return (
		<LocationsContext.Provider
			value={{
				locationStats,
				locations,
				createLocation,
				updateLocation,
				archiveLocation,
				partnerLocationStats,
				atPartnerLocationStats
			}}
		>
			{children}
		</LocationsContext.Provider>
	);
};

export const LocationsConsumer = LocationsContext.Consumer;

export const useLocations = () => useContext(LocationsContext);
