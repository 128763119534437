import { createReducer } from '../../common/util/reducerUtil';
const initialState = { data: false, report: false };

export const setLoaded = (state, payload) => {
	return { ...state, ...payload };
};

export default createReducer(initialState, {
	SET_LOADED: setLoaded
});
