import React from 'react';
import { BrandsProvider } from '../brands';
import { KegsProvider } from '../kegs';
import { LocationsProvider } from '../locations';
import { ClientsProvider } from '../clients';
import { CompaniesProvider } from '../companies';
import { PartnersProvider } from '../partners';
import { BatchesProvider } from '../batches';
import { ActivityProvider } from '../activity';
import { CompanyProvider } from '../company';
import { ReportsProvider } from '../reports';
import { IntegrationsProvider } from '../integrations';
import { FillsProvider } from '../fills';
import VolumeProvider from '../volume';
import VolumeByBrandProvider from '../volume/byBrand';
import { UsersProvider } from '../users';
import MapContextProvider from '../googleMap';

const DataProvider = ({ children }) => {
	return (
		<MapContextProvider>
			<IntegrationsProvider>
				<CompanyProvider>
					<CompaniesProvider>
						<KegsProvider>
							<ReportsProvider>
								<LocationsProvider>
									<ClientsProvider>
										<BrandsProvider>
											<BatchesProvider>
												<PartnersProvider>
													<ActivityProvider>
														<FillsProvider>
															<VolumeByBrandProvider>
																<VolumeProvider>
																	<UsersProvider>{children}</UsersProvider>
																</VolumeProvider>
															</VolumeByBrandProvider>
														</FillsProvider>
													</ActivityProvider>
												</PartnersProvider>
											</BatchesProvider>
										</BrandsProvider>
									</ClientsProvider>
								</LocationsProvider>
							</ReportsProvider>
						</KegsProvider>
					</CompaniesProvider>
				</CompanyProvider>
			</IntegrationsProvider>
		</MapContextProvider>
	);
};

export default DataProvider;
