import {
	asyncActionStart,
	asyncActionFinish,
	asyncActionError
} from '../../app/async/asyncActions';

// UPDATE USER //////////////////////////////////////////////////////////////////
export const markAsSeen = (userId) => {
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		let updatedUser = {
			showWelcome: false
		};
		try {
			dispatch(asyncActionStart());
			await firestore.update(`users/${userId}`, updatedUser);
			dispatch(asyncActionFinish());
		} catch (error) {
			console.error(error);
			dispatch(asyncActionError());
		}
	};
};
