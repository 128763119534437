import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
/* import { useAuthContext } from "../auth"; */
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth, useUser } from '../auth';
import subMonths from 'date-fns/sub_months';
import isAfter from 'date-fns/is_after';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

import Axios from 'axios';
const KegsContext = createContext({});

export const KegsProvider = ({ children }) => {
	// const [kegNotAvailableMessage, setKegNotAvailableMessage] = useState(null);
	const firebase = useFirebase();
	// const [newKegs, setNewKegs] = useState([]);
	const { claims } = useAuth();
	const user = useUser();
	const [openNewKegsModal, setOpenNewKegsModal] = useState(false);
	const [oldKegId, setOldKegId] = useState(null);
	const [kegs, setKegs] = useState({
		companyKegs: [],
		partnerKegs: [],
		distributorKegs: []
	});
	const handleOpenNewKegsModal = (oldId) => {
		setOpenNewKegsModal(true);
		setOldKegId(oldId);
	};
	const handleCloseNewKegsModal = () => {
		setOpenNewKegsModal(false);
		setOldKegId(null);
	};

	useEffect(() => {
		return claims?.companyLink
			? firebase.companyKegs(claims?.companyLink).onSnapshot(
					(snap) =>
						setKegs((prev) => ({
							...prev,
							companyKegs: transformCollectionSnap(snap)
						})),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims]);

	useEffect(() => {
		if (user === undefined || user?.companyObjs === undefined) return;

		const links = user.companyObjs.map(company => company.link);

		return user?.companyObjs
			? firebase.distributorKegs(links).onSnapshot(
				(snap) => {
						setKegs((prev) => ({
							...prev,
							distributorKegs: transformCollectionSnap(snap)
						}))
					},
					(err) => console.log(err)
			  )
			: () => console.log('No Company Objects');
	}, [user]);



	useEffect(() => {
		return claims?.companyLink
			? firebase.partnerKegs(claims?.companyLink).onSnapshot(
					(snap) =>
						setKegs((prev) => ({
							...prev,
							partnerKegs: transformCollectionSnap(snap)
						})),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims]);
	// }, [claims, firebase]);

	// useEffect(() => {
	// 	firebase.getSingleKeg('KT5853');
	// }, [firebase]);
	const statusStats = useMemo(() => {
		if ((kegs.companyKegs && kegs.partnerKegs)) {
			const activeKegs = ( [...kegs.companyKegs, ...kegs.partnerKegs] )?.filter(
				(k) => !k.isArchive
			);
			const kegsNewArray = activeKegs?.filter((keg) => keg.kegStatus === 'new');
			const kegsEmptyArray = activeKegs?.filter(
				(keg) => !keg.isFilled && !keg.isNew
			);
			const kegsFilledArray = activeKegs?.filter(
				(keg) => keg.isFilled && !keg.isNew
			);
			const kegsWarehouseArray = activeKegs?.filter(
				(keg) =>
					keg.locationStatus?.includes('warehouse') &&
					keg.locationStatus !== 'in_warehouse__issue'
			);

			const kegsDispatchedArray = activeKegs?.filter((k) => {
				if (claims.companyLink === k.partnerLink) {
					return (
						k.locationStatus?.includes('dispatch') ||
						k.locationStatus === 'receive__partner'
						// k.locationStatus === 'in_warehouse__partner'
					);
				}
				return (
					k.locationStatus?.includes('dispatch') ||
					k.locationStatus === 'receive__partner' ||
					k.locationStatus === 'in_warehouse__partner'
				);
			});
			const kegsIssueArray = activeKegs?.filter(
				(keg) => keg.locationStatus === 'in_warehouse__issue'
			);
			const statusStats = {
				all:
					kegs.companyKegs && kegs.partnerKegs
						? [...kegs.companyKegs, ...kegs.partnerKegs]?.length
						: 0,
				new: kegsNewArray ? kegsNewArray.length : 0,
				empty: kegsEmptyArray ? kegsEmptyArray.length : 0,
				filled: kegsFilledArray ? kegsFilledArray.length : 0,
				warehouse: kegsWarehouseArray ? kegsWarehouseArray.length : 0,
				dispatched: kegsDispatchedArray ? kegsDispatchedArray.length : 0,
				issue: kegsIssueArray ? kegsIssueArray.length : 0,
				partner: kegs.partnerKegs ? kegs.partnerKegs?.length : 0,
				own: kegs.companyKegs ? kegs.companyKegs?.length : 0
				// partner: kegs.partnerKegs ? kegs.partnerKegs.length : 0
			};
			return statusStats;
		}
	}, [kegs, claims]);

	const stockStats = useMemo(() => {
		if (kegs.companyKegs) {
			const stockTypeAllArray = kegs.companyKegs?.filter(
				(k) => k.type === 'keg' || k.type === 'fridge' || k.type === 'gas'
			);
			const stockTypeKegsArray = kegs.companyKegs?.filter(
				(k) => k.type === 'keg'
			);
			const stockTypeFridgeArray = kegs.companyKegs?.filter(
				(k) => k.type === 'fridge'
			);
			const stockTypeGasArray = kegs.companyKegs?.filter(
				(k) => k.type === 'gas'
			);

			const stockStats = {
				all: stockTypeAllArray.length,
				kegs: stockTypeKegsArray.length,
				fridge: stockTypeFridgeArray.length,
				gas: stockTypeGasArray.length
			};

			return stockStats;
		}
	}, [kegs]);

	const getDispatchKegs = (warehouse, client, codes) => {
		let availableWarehouseKegs = [];
		let availablePatnerKegs = [];

		const warehouseLink = warehouse ? `${warehouse.id}__${warehouse.name}` : '';
		const clientLink = client ? `${client.id}__${client.name}` : '';
		if (warehouse) {
			const tapTypeKegs = kegs.companyKegs?.filter(
				(keg) =>
					keg.locationStatus === 'in_warehouse__own' &&
					keg.type === 'tap' &&
					keg.locationLink === warehouseLink
			);
			const warehouseKegs = kegs.companyKegs?.filter(
				(keg) =>
					keg.locationStatus === 'in_warehouse__own' &&
					keg.isFilled &&
					keg.locationLink === warehouseLink &&
					keg.type !== 'tap'
			);
			availableWarehouseKegs = [...tapTypeKegs, ...warehouseKegs];
		}
		if (warehouse) {
			const tapTypePatnerKegs = kegs.partnerKegs?.filter(
				(keg) =>
					keg.locationStatus === 'in_warehouse__partner' &&
					keg.type === 'tap' &&
					keg.locationLink === warehouseLink
			);
			const patnerKegs = kegs.partnerKegs?.filter(
				(keg) =>
					keg.locationStatus === 'in_warehouse__partner' &&
					keg.isFilled &&
					keg.locationLink === warehouseLink &&
					keg.type !== 'tap'
			);
			availablePatnerKegs = [...tapTypePatnerKegs, ...patnerKegs];
		}
		let availableKegs = [...availableWarehouseKegs, ...availablePatnerKegs];

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}
		if (client) {
			if (client.type === 'partner') {
				if (warehouseLink) {
					const ownKegs = kegs.companyKegs?.filter(
						(keg) =>
							keg.locationStatus === 'in_warehouse__own' &&
							keg.locationLink === warehouseLink
					);
					const partnerKegs = kegs.partnerKegs?.filter(
						(keg) =>
							keg.locationStatus === 'in_warehouse__partner' &&
							keg.locationLink === warehouseLink &&
							keg.companyLink === clientLink
					);
					availableKegs = [...partnerKegs, ...ownKegs];
				}

				if (codes.length > 0) {
					availableKegs = availableKegs.filter(
						(keg) => codes.indexOf(keg.id) === -1
					);
				}
			}
		}
		return availableKegs?.sort((a, b) => (a.label > b.label ? 1 : -1));
	};

	const getDistributorReceiveKegs = (codes) => {
		// Allow any of your own kegs (Except "Issue" $ "New" to be returned - main use case is to accept any keg from partners)
		const dispatchedKegs = kegs.distributorKegs
			? kegs.distributorKegs
					?.filter(
						(keg) =>
							keg.locationStatus === 'dispatched__client' ||
							keg.locationStatus === 'receive__partner' ||
							keg.locationStatus === 'in_warehouse__partner' ||
							keg.kegStatus === 'dispatched'
					)
					?.sort((a, b) => (a.label > b.label ? 1 : -1))
			: [];
		const kegNotReceived = kegs.distributorKegs
			? kegs.distributorKegs
					?.filter(
						(keg) =>
							keg.locationStatus === 'dispatched__partner' ||
							keg.locationStatus === 'receive__partner'
					)
					?.sort((a, b) => (a.label > b.label ? 1 : -1))
			: [];
		const receiveKegs = kegs.partnerKegs
			? kegs.partnerKegs
					?.filter(
						(keg) =>
							(keg.locationStatus === 'dispatched__client' ||
								keg.locationStatus === 'dispatched__partner') &&
							keg.responsibleParty === 'partner'
					)
					?.sort((a, b) => (a.label > b.label ? 1 : -1))
			: [];
	
		let availableKegs = [...dispatchedKegs, ...receiveKegs]?.sort((a, b) =>
			a.label > b.label ? 1 : -1
		);

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}

		const dispatchedPatnerKegs = [];
		// const dispatchedPatnerKegs = availableKegs?.filter(
		// 	(keg) =>
		// 		keg.dispatchedTo === 'partner' ||
		// 		(keg.locationStatus === 'dispatched__partner' &&
		// 			keg.responsibleParty === 'partner')
		// );

		return { availableKegs, dispatchedPatnerKegs, receiveKegs, kegNotReceived };
	};

	const getReceiveKegs = (codes) => {
		// Allow any of your own kegs (Except "Issue" $ "New" to be returned - main use case is to accept any keg from partners)
		const dispatchedKegs = kegs.companyKegs
			? kegs.companyKegs
					?.filter(
						(keg) =>
							keg.locationStatus === 'dispatched__client' ||
							keg.locationStatus === 'receive__partner' ||
							keg.locationStatus === 'in_warehouse__partner' ||
							keg.kegStatus === 'dispatched'
					)
					?.sort((a, b) => (a.label > b.label ? 1 : -1))
			: [];
		const kegNotReceived = kegs.companyKegs
			? kegs.companyKegs
					?.filter(
						(keg) =>
							keg.locationStatus === 'dispatched__partner' ||
							keg.locationStatus === 'receive__partner'
					)
					?.sort((a, b) => (a.label > b.label ? 1 : -1))
			: [];
		const receiveKegs = kegs.partnerKegs
			? kegs.partnerKegs
					?.filter(
						(keg) =>
							(keg.locationStatus === 'dispatched__client' ||
								keg.locationStatus === 'dispatched__partner') &&
							keg.responsibleParty === 'partner'
					)
					?.sort((a, b) => (a.label > b.label ? 1 : -1))
			: [];
		// const dispatchedPatnerKegs =
		// 	kegs.partnerKegs && kegs.warehouseKegs
		// 		? [...kegs.partnerKegs, ...kegs.warehouseKegs]?.filter(
		// 				(keg) =>
		// 					keg.dispatchedTo === 'partner' ||
		// 					(keg.locationStatus === 'dispatched__partner' &&
		// 						keg.responsibleParty === 'partner')
		// 		  )
		// 		: [];

		// const dispatchedPatnerKegs = kegs.companyKegs
		// 	? kegs.companyKegs?.filter(
		// 			(keg) =>
		// 				keg.responsibleParty === 'partner' &&
		// 				(keg.locationStatus === 'dispatched__client' ||
		// 					keg.kegStatus === 'dispatched')
		// 	  )
		// 	: [];

		let availableKegs = [...dispatchedKegs, ...receiveKegs]?.sort((a, b) =>
			a.label > b.label ? 1 : -1
		);

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}

		const dispatchedPatnerKegs = availableKegs?.filter(
			(keg) =>
				keg.dispatchedTo === 'partner' ||
				(keg.locationStatus === 'dispatched__partner' &&
					keg.responsibleParty === 'partner')
		);

		return { availableKegs, dispatchedPatnerKegs, receiveKegs, kegNotReceived };
	};

	const getOnboardKegs = (codes) => {
		let availableKegs = kegs.companyKegs?.filter(
			(keg) => keg.kegStatus === 'new' || keg.isNew
		);

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}

		return availableKegs?.sort((a, b) => (a.label > b.label ? 1 : -1));
	};
	const getFillKegs = (warehouse, codes, partner) => {
		const warehouseLink = warehouse ? `${warehouse.id}__${warehouse.name}` : '';
		let availableKegs = [];
		let emptyKegs = [];
		if (partner) {
			emptyKegs = kegs.partnerKegs
				?.filter((k) => getObjFromLink(k.companyLink).id === partner.id)
				?.filter((keg) => keg.kegStatus === 'empty' || !keg.isFilled);
		} else {
			emptyKegs = [...kegs.companyKegs, ...kegs.partnerKegs]?.filter(
				(keg) => keg.kegStatus === 'empty' || !keg.isFilled
			);
		}

		if (warehouse) {
			availableKegs = emptyKegs.filter(
				(keg) =>
					keg.locationLink === warehouseLink &&
					(keg.locationStatus === 'in_warehouse__own' ||
						keg.locationStatus === 'in_warehouse__partner')
			);
		}

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}

		return availableKegs?.sort((a, b) => (a.label > b.label ? 1 : -1));
	};

	const getTransferKegs = (warehouse, codes) => {
		const warehouseKegs = kegs.companyKegs?.filter(
			(keg) => keg.locationStatus === 'in_warehouse__own'
		);
		const partnerKegs = kegs.partnerKegs?.filter(
			(keg) => keg.locationStatus === 'in_warehouse__partner'
		);

		let availableKegs = [];
		if (warehouse) {
			availableKegs = [...warehouseKegs, ...partnerKegs].filter(
				(keg) => keg.locationLink === `${warehouse.id}__${warehouse.name}`
			);
		}

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}

		return availableKegs?.sort((a, b) => (a.label > b.label ? 1 : -1));
	};

	const getUsedKegs = (codes) => {
		let availableKegs = kegs.companyKegs?.filter(
			(keg) => keg.kegStatus !== 'new'
		);

		if (codes.length > 0) {
			availableKegs = availableKegs.filter(
				(keg) => codes.indexOf(keg.id) === -1
			);
		}

		return availableKegs?.sort((a, b) => (a.label > b.label ? 1 : -1));
	};

	const getAvailableKegs = ({ warehouse, client, codes, action, partner }) => {
		// only full kegs
		let availableKegs = [];

		switch (action) {
			case 'dispatch':
				availableKegs =  getDispatchKegs(warehouse, client, codes);
				break;
			case 'receive':
				availableKegs = !claims.isDistributor ?getReceiveKegs(codes): getDistributorReceiveKegs(codes);
				break;
			case 'onboard':
				availableKegs = getOnboardKegs(codes);
				break;
			case 'fill':
				availableKegs = getFillKegs(warehouse, codes, partner);
				break;
			case 'transfer':
				availableKegs = getTransferKegs(warehouse, codes);
				break;
			case 'used':
				availableKegs = getUsedKegs(codes);
				break;
			default:
				break;
		}

		return availableKegs;
	};

	const getIssueReports = (setReports, kegId) => {
		return firebase
			.reports('reports_issue_kegs')
			.where('companyLink', '==', claims.companyLink)
			.where('issueKeg', '==', kegId)
			.onSnapshot(
				(snap) => setReports(transformCollectionSnap(snap)),
				(err) => console.log(err)
			);
	};

	const migrateToNewKeg = (newKeg, oldKeg, newData) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		return Axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/migrate`,
			{
				newKeg,
				newData,
				oldKeg
			},
			config
		);
		//	return firebase.migrateToNewKeg(newKeg, newData);
	};
	const kegsDispatchedSixMonthsAgo = useMemo(() => {
		return kegs?.companyKegs?.filter((keg) =>
			isAfter(keg?.dateDispatched?.toDate(), new Date(subMonths(new Date(), 6)))
		);
	}, [kegs]);
	// const kegsReturnedWithinFourteenDays = {
	// 	name: 'within 14',
	// 	value: kegsDispatchedSixMonthsAgo
	// 		?.filter((keg) => keg?.dateReceived && keg?.dateDispatched)
	// 		.filter(
	// 			(keg) =>
	// 				differenceInCalendarDays(
	// 					keg?.dateDispatched?.toDate(),
	// 					keg?.dateReceived.toDate()
	// 				) <= 14
	// 		)?.length
	// };

	// const kegsReturnedWithinFourteenAndThirthDays = {
	// 	name: 'within 30 and 14',
	// 	value: kegsDispatchedSixMonthsAgo
	// 		?.filter((keg) => keg?.dateReceived && keg?.dateDispatched)
	// 		.filter(
	// 			(keg) =>
	// 				differenceInCalendarDays(
	// 					keg?.dateDispatched?.toDate(),
	// 					keg?.dateReceived.toDate()
	// 				) >= 14 &&
	// 				differenceInCalendarDays(
	// 					keg?.dateDispatched?.toDate(),
	// 					keg?.dateReceived.toDate()
	// 				) <= 30
	// 		)?.length
	// };

	// const kegsReturnedAfterThirthDays = {
	// 	name: 'after 30 days',
	// 	value: kegsDispatchedSixMonthsAgo
	// 		?.filter((keg) => keg?.dateReceived && keg?.dateDispatched)
	// 		.filter(
	// 			(keg) =>
	// 				differenceInCalendarDays(
	// 					keg?.dateDispatched?.toDate(),
	// 					keg?.dateReceived.toDate()
	// 				) >= 30
	// 		)?.length
	// };

	const arrayOfResult = useMemo(() => {
		if (kegs) {
			return [
				{
					name: '-14',
					value: kegsDispatchedSixMonthsAgo
						?.filter((keg) => keg?.dateReceived && keg?.dateDispatched)
						.filter(
							(keg) =>
								differenceInCalendarDays(
									keg?.dateDispatched?.toDate(),
									keg?.dateReceived.toDate()
								) <= 14
						)?.length
				},
				{
					name: '14 - 30',
					value: kegsDispatchedSixMonthsAgo
						?.filter((keg) => keg?.dateReceived && keg?.dateDispatched)
						.filter(
							(keg) =>
								differenceInCalendarDays(
									keg?.dateDispatched?.toDate(),
									keg?.dateReceived.toDate()
								) >= 14 &&
								differenceInCalendarDays(
									keg?.dateDispatched?.toDate(),
									keg?.dateReceived.toDate()
								) <= 30
						)?.length
				},
				{
					name: '30+',
					value: kegsDispatchedSixMonthsAgo
						?.filter((keg) => keg?.dateReceived && keg?.dateDispatched)
						.filter(
							(keg) =>
								differenceInCalendarDays(
									keg?.dateDispatched?.toDate(),
									keg?.dateReceived.toDate()
								) >= 30
						)?.length
				}
			];
		}
	}, [kegs, kegsDispatchedSixMonthsAgo]);

	const maximumNumber = useMemo(() => {
		if (kegs) {
			return arrayOfResult.reduce(
				(prev, current) => (prev.value > current.value ? prev : current),
				1
			);
		}
	}, [kegs, arrayOfResult]);

	// useEffect(() => {
	// 	const companyLink = 'u7Cy5BufZbls7j62QHtV__testing brewery';
	// 	firebase.companyKegs(companyLink).onSnapshot(
	// 		(snap) => {
	// 			const kegs = transformCollectionSnap(snap);
	// 			firebase.multipleUpdate(kegs);
	// 			console.log(kegs, 'kegs to reset');
	// 		},
	// 		(err) => console.log(err)
	// 	);
	// }, [firebase]);

	return (
		<KegsContext.Provider
			value={{
				...kegs,
				statusStats,
				getAvailableKegs,
				stockStats,
				getIssueReports,
				migrateToNewKeg,
				openNewKegsModal,
				oldKegId,
				handleCloseNewKegsModal,
				handleOpenNewKegsModal,
				// kegsReturnedAfterThirthDays,
				// kegsReturnedWithinFourteenAndThirthDays,
				// kegsReturnedWithinFourteenDays,
				maximumNumber
			}}
		>
			{children}
		</KegsContext.Provider>
	);
};

export const KegsConsumer = KegsContext.Consumer;

export const useKegs = () => useContext(KegsContext);
