import { getObjFromLink } from '../helpers';

export const formatBrandData = (brand, companyLink, isNew) => {
	const currentTime = new Date();
	let brandFields = {};

	if (isNew) {
		brandFields = {
			...brand,
			dateCreated: currentTime
		};
	} else {
		brandFields = {
			...brand,
			dateUpdated: currentTime
		};
	}
	brandFields.companyLink = companyLink;
	brandFields.companyObj = getObjFromLink(companyLink);

	return {
		...brandFields
	};
};
