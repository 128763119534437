import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';

const CompaniesContext = createContext({});

export const CompaniesProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();

	const [companies, setCompanies] = useState();

	useEffect(() => {
		return claims?.companyLink
			? firebase.companies().onSnapshot(
					(snap) => setCompanies(transformCollectionSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('not admin');
	}, [claims, firebase]);

	return (
		<CompaniesContext.Provider
			value={{
				companies: companies?.sort((a, b) => {
					if (a.name > b.name) {
						return 1;
					}
					if (a.name < b.name) {
						return -1;
					}
					return 0;
				})
			}}
		>
			{children}
		</CompaniesContext.Provider>
	);
};

export const CompaniesConsumer = CompaniesContext.Consumer;

export const useCompanies = () => useContext(CompaniesContext);
