import { createReducer } from '../../app/common/util/reducerUtil';
import { FETCH_CLIENT_KEGS } from '../../app/../app/context/kegs/constants';

const initialState = [];

export const fetchClientKegs = (state, payload) => {
	return payload.kegsClient;
};

export default createReducer(initialState, {
	[FETCH_CLIENT_KEGS]: fetchClientKegs
});
