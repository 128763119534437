import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxToaster from 'react-redux-toastr';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import App from './app/layout/App';
import ScrollToTop from './app/common/util/ScrollToTop';
import ErrorBoundary from './app/errorBoundary/ErrorBoundary';
import { configureStore } from './app/store/configureStore';
const store = configureStore();

const rootEl = document.getElementById('root');

let render = () => {
	ReactDOM.render(
		<ErrorBoundary>
			<Provider store={store}>
				<BrowserRouter>
					<ScrollToTop>
						<ReduxToaster
							position="top-right"
							transitionIn="bounceInDown"
							transitionOut="bounceOutUp"
							progressBar={false}
						/>
						<App />
					</ScrollToTop>
				</BrowserRouter>
			</Provider>
		</ErrorBoundary>,
		rootEl
	);
};

if (module.hot) {
	module.hot.accept('./app/layout/App', () => {
		setTimeout(render);
	});
}
store.firebaseAuthIsReady.then(() => {
	render();
});
