import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { toastr } from 'react-redux-toastr';
import { useKegs } from '../kegs';
import { useCompany } from '../company';

const PartnersContext = createContext({});

export const PartnersProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const [partners, setPartners] = useState();
	const { partnerKegs } = useKegs();
	const { company } = useCompany();
	useEffect(() => {
		return claims?.companyLink
			? firebase.partners(getObjFromLink(claims.companyLink).id).onSnapshot(
					(snap) => setPartners(transformCollectionSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);

	const toggleLink = async (isLinked, partner) => {
		const currentTime = new Date();
		const companyPartnersRef = firebase.partners(
			getObjFromLink(claims.companyLink).id
		);
		const partnerPartnersRef = firebase.partners(partner.id);
		if (!isLinked) {
			let batch = firebase.db.batch();
			// ADD PARTNER

			const companyPartner = {
				companyLink: `${partner.id}__${partner.name}`,
				companyObj: {
					name: partner.name,
					id: partner.id
				},
				//  partnerCode: partner.partnerCode,
				dateLinked: currentTime
			};
			const partnerPartner = {
				companyLink: claims.companyLink,
				companyObj: getObjFromLink(claims.companyLink),
				// partnerCode: company.partnerCode,
				dateLinked: currentTime
			};

			await batch.set(companyPartnersRef.doc(), companyPartner);
			await batch.set(partnerPartnersRef.doc(), partnerPartner);
			return await batch.commit();
		} else {
			let batch = firebase.db.batch();
			// REMOVE PARTNER
			const kegsRef = await firebase.db.collection('kegs');

			if (!partner.companyLink) {
				partner.companyLink = `${partner.id}__${partner.name}`;
			}
			let partnerKegsCount = partnerKegs?.filter(
				(k) => k.partnerLink === partner.companyLink
			).length;

			await kegsRef
				.where('companyLink', '==', partner.companyLink)
				.where('partnerLink', '==', claims.companyLink)
				.where('isArchive', '==', false)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach(function(doc) {
						partnerKegsCount++;
					});
				});

			if (partnerKegsCount > 0) {
				toastr.error(
					'Oops',
					`Cannot unlink this partner currently, you still have ${partnerKegsCount} ${
						partnerKegsCount > 1 ? 'kegs' : 'keg'
					} shared between you.`
				);
			} else {
				if (partner.companyObj) {
					partner.id = partner.companyObj.id;
				}

				const companySnap = await companyPartnersRef
					.where('companyObj.id', '==', partner.id)
					.get();
				companySnap.forEach(async (p) => await batch.delete(p.ref));

				const partnerSnap = await partnerPartnersRef
					.where('companyLink', '==', claims.companyLink)
					.get();
				partnerSnap.forEach(async (p) => await batch.delete(p.ref));

				return await batch.commit();
			}
		}
	};

	return (
		<PartnersContext.Provider value={{ partners, toggleLink }}>
			{children}
		</PartnersContext.Provider>
	);
};

export const PartnersConsumer = PartnersContext.Consumer;

export const usePartners = () => useContext(PartnersContext);
