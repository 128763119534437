import React from 'react';
import { NavLink } from 'react-router-dom';
import { Statistic } from 'semantic-ui-react';
import SimpleLoader from '../../../../app/layout/SimpleLoader';

const DashboardKegStatus = ({ title, description, className, value, to }) => {
	const classes = ['keg-status-card dashboard', className].join(' ');
	return (
		<div className={classes}>
			<NavLink to={to}>
				<Statistic>
					{value !== undefined ? (
						<Statistic.Value>{value}</Statistic.Value>
					) : (
						<SimpleLoader color="grey" />
					)}
				</Statistic>
				<div className="title">{title}</div>
				<div className="description">{description}</div>
			</NavLink>
		</div>
	);
};

export default DashboardKegStatus;
