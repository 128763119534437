import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Table, Popup } from 'semantic-ui-react';
import {
	getAtPartnerLocationStats,
	getPartnerLocationStats
} from '../dashboardActions';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocations } from '../../../app/context/locations';

const TableContent = styled.div`
	margin-top: 1em;
`;

export default function DashboardPartnersStats({ title }) {
	const { partnerLocationStats, atPartnerLocationStats } = useLocations();

	return partnerLocationStats?.locationTotals?.length !== 0 ||
		atPartnerLocationStats?.locationTotals?.length !== 0 ? (
		<div className="widget margin-bottom">
			<div className="widget-table-header">
				<h2>Partners</h2>
				<Button size="mini" as={Link} to={`/reports/partner-movements`}>
					Full Report
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
			{partnerLocationStats &&
			partnerLocationStats.locationTotals &&
			partnerLocationStats.locationTotals.length !== 0 ? (
				<TableContent>
					<h4>Your partner's kegs in your warehouses</h4>
					<Table
						basic="very"
						compact="very"
						celled
						collapsing
						className="brands-table"
					>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell textAlign="center">
									Empty Kegs ({partnerLocationStats.countEmpty})
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Filled Kegs ({partnerLocationStats.countFilled})
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Kegs with clients ({partnerLocationStats.withClients})
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{partnerLocationStats &&
								partnerLocationStats.locationTotals.map((location) => (
									<Table.Row key={location.id}>
										<Table.Cell className="label">
											{location.name} ({location.partner})
										</Table.Cell>
										<Table.Cell
											textAlign="center"
											className="value labelEmptyKegs"
										>
											{location.countEmpty > 0 ? (
												<Popup
													trigger={<p>{location.countEmpty}</p>}
													content={
														<div className="keg-count-label-wrapper">
															{location.empty &&
																location.empty.map((keg) => (
																	<span
																		className={`${location.empty < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg}
																	>
																		{keg}
																	</span>
																))}
														</div>
													}
													inverted
													position="top center"
												/>
											) : (
												location.countEmpty
											)}
										</Table.Cell>
										<Table.Cell
											textAlign="center"
											className="value labelWarehouseKegs"
										>
											{location.countFilled > 0 ? (
												<Popup
													trigger={<p>{location.countFilled}</p>}
													content={
														<div className="keg-count-label-wrapper">
															{location.filled &&
																location.filled.map((keg) => (
																	<span
																		className={`${location.filled < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg}
																	>
																		{keg}
																	</span>
																))}
														</div>
													}
													inverted
													position="top center"
												/>
											) : (
												location.countFilled
											)}
										</Table.Cell>
										<Table.Cell
											textAlign="center"
											className="value labelWarehouseKegs"
										>
											{location.countWithClients > 0 ? (
												<Popup
													trigger={<p>{location.countWithClients}</p>}
													content={
														<div className="keg-count-label-wrapper">
															{location.withClients &&
																location.withClients.map((keg) => (
																	<span
																		className={`${location.withClients < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg}
																	>
																		{keg}
																	</span>
																))}
														</div>
													}
													inverted
													position="top center"
												/>
											) : (
												location.withClients
											)}
										</Table.Cell>
									</Table.Row>
								))}
						</Table.Body>
					</Table>
				</TableContent>
			) : (
				<div></div>
			)}
			{atPartnerLocationStats &&
			atPartnerLocationStats.locationTotals &&
			atPartnerLocationStats.locationTotals.length !== 0 ? (
				<TableContent>
					<h4>Your kegs in your partner's warehouses</h4>
					<Table
						basic="very"
						compact="very"
						celled
						collapsing
						className="brands-table"
					>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell textAlign="center">
									Empty Kegs ({atPartnerLocationStats.countEmpty})
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Filled Kegs ({atPartnerLocationStats.countFilled})
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Kegs with clients ({atPartnerLocationStats.withClients})
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{atPartnerLocationStats &&
								atPartnerLocationStats.locationTotals.map((location) => (
									<Table.Row key={location.id}>
										<Table.Cell className="label">
											{location.name} ({location.partner})
										</Table.Cell>
										<Table.Cell
											textAlign="center"
											className="value labelEmptyKegs"
										>
											{location.countEmpty > 0 ? (
												<Popup
													trigger={<p>{location.countEmpty}</p>}
													content={
														<div className="keg-count-label-wrapper">
															{location.empty &&
																location.empty.map((keg) => (
																	<span
																		className={`${location.empty < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg}
																	>
																		{keg}
																	</span>
																))}
														</div>
													}
													inverted
													position="top center"
												/>
											) : (
												location.countEmpty
											)}
										</Table.Cell>
										<Table.Cell
											textAlign="center"
											className="value labelWarehouseKegs"
										>
											{location.countFilled > 0 ? (
												<Popup
													trigger={<p>{location.countFilled}</p>}
													content={
														<div className="keg-count-label-wrapper">
															{location.filled &&
																location.filled.map((keg) => (
																	<span
																		className={`${location.filled < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg}
																	>
																		{keg}
																	</span>
																))}
														</div>
													}
													inverted
													position="top center"
												/>
											) : (
												location.countFilled
											)}
										</Table.Cell>
										<Table.Cell
											textAlign="center"
											className="value labelWarehouseKegs"
										>
											{location.countWithClients > 0 ? (
												<Popup
													trigger={<p>{location.countWithClients}</p>}
													content={
														<div className="keg-count-label-wrapper">
															{location.withClients &&
																location.withClients.map((keg) => (
																	<span
																		className={`${location.withClients < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg}
																	>
																		{keg}
																	</span>
																))}
														</div>
													}
													inverted
													position="top center"
												/>
											) : (
												location.countWithClients
											)}
										</Table.Cell>
									</Table.Row>
								))}
						</Table.Body>
					</Table>
				</TableContent>
			) : (
				<div></div>
			)}
		</div>
	) : null;
}
