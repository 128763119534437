import { useMemo } from 'react';
import { getObjFromLink } from '../context/helpers';

export const totalVolumeHelper = (kegsFilterd) =>
	kegsFilterd
		.map(
			(keg) =>
				keg?.kegsDispatchedDetail?.reduce(
					(acc, curr) => acc + (+curr.volume || 0),
					0
				) || 0
		)
		?.reduce((acc, curr) => acc + curr, 0);

export const TotalKegsHelper = (kegsFilterd) => {
	//console.log(kegsFilterd);
	return kegsFilterd
		?.map((keg) => keg?.kegsDispatchedDetail?.length || 0)
		.reduce((acc, curr) => acc + curr, 0);
};

export const filterdTotalVolumeBrand = (filterByBrand, brand) =>
	filterByBrand
		.map(
			(keg) =>
				(keg?.kegsDispatchedDetail &&
					keg?.kegsDispatchedDetail
						.filter((k) => k?.brandObj?.id === getObjFromLink(brand)?.id)
						.reduce((acc, curr) => acc + (+curr.volume || 0), 0)) ||
				0
		)
		.reduce((acc, curr) => acc + curr, 0);

export const filterdTotalKegsBrand = (filterByBrand, brand) =>
	filterByBrand
		?.map(
			(keg) =>
				keg?.kegsDispatchedDetail?.filter(
					(k) => k?.brandObj?.id === getObjFromLink(brand)?.id
				)?.length
		)
		.reduce((acc, curr) => acc + (+curr || 0), 0);

export const clientHelper = (
	client,
	brand,
	filterdKegs,
	setFilterdKegs,
	setTotalVolume,
	setTotalKegs
) => {
	const kegsFilterd = filterdKegs.filter((keg) => {
		return keg.clientLink === client;
	});
	setFilterdKegs(kegsFilterd);
	const filterdTotalKegs = TotalKegsHelper(kegsFilterd);
	const filterdTotalVolume = totalVolumeHelper(kegsFilterd);

	setTotalVolume(filterdTotalVolume);
	setTotalKegs(filterdTotalKegs);
	if (brand) {
		const filterByBrand = kegsFilterd.filter((keg) =>
			keg?.brandsDispatched?.find((k) => k.id === getObjFromLink(brand).id)
		);

		setFilterdKegs(filterByBrand);
		const filterdTotalVolume = filterdTotalVolumeBrand(filterByBrand, brand);
		const filterdTotalKegs = filterdTotalKegsBrand(filterByBrand, brand);

		setTotalVolume(filterdTotalVolume);
		setTotalKegs(filterdTotalKegs);
	}
};

export const brandHelper = (
	client,
	brand,
	filterdKegs,
	setFilterdKegs,
	setTotalVolume,
	setTotalKegs
) => {
	const filterByBrand = filterdKegs.filter((keg) =>
		keg?.brandsDispatched?.find((k) => k.id === getObjFromLink(brand).id)
	);

	setFilterdKegs(filterByBrand);
	const filterdTotalVolume = filterdTotalVolumeBrand(filterByBrand, brand);
	const filterdTotalKegs = filterdTotalKegsBrand(filterByBrand, brand);

	setTotalVolume(filterdTotalVolume);
	setTotalKegs(filterdTotalKegs);
	if (client) {
		const kegsFilterd = filterByBrand.filter((keg) => {
			return keg.clientLink === client;
		});
		setFilterdKegs(kegsFilterd);
		const filterdTotalKegs = TotalKegsHelper(kegsFilterd);
		const filterdTotalVolume = totalVolumeHelper(kegsFilterd);

		setTotalVolume(filterdTotalVolume);
		setTotalKegs(filterdTotalKegs);
	}
};
