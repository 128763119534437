import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { ServiceM8Provider } from './serviceM8';

const IntegrationsContext = createContext({});

export const IntegrationsProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const [integrations, setIntegrations] = useState();

	useEffect(() => {
		return claims?.companyLink
			? firebase.integrations(getObjFromLink(claims.companyLink).id).onSnapshot(
					(snap) => setIntegrations(transformCollectionSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);

	return (
		<IntegrationsContext.Provider value={{ integrations }}>
			<ServiceM8Provider>{children}</ServiceM8Provider>
		</IntegrationsContext.Provider>
	);
};

export const IntegrationsConsumer = IntegrationsContext.Consumer;

export const useIntegrations = () => useContext(IntegrationsContext);
