import React, { createContext, useContext, useState, useEffect } from 'react';
import {
	getObjFromLink,
	transformDocSnap,
	transformCollectionSnap
} from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { formatCompanyData } from './formatting';

const CompanyContext = createContext({});

export const CompanyProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const [company, setCompany] = useState();
	useEffect(() => {
		return claims?.companyLink
			? firebase.company(getObjFromLink(claims.companyLink).id).onSnapshot(
					(snap) => setCompany(transformDocSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);

	const updateCompany = async (company) => {
		const updatedCompany = formatCompanyData(company);
		firebase
			.company(getObjFromLink(claims.companyLink).id)
			.update(updatedCompany);
	};

	const archiveCompany = async (isArchive, id) => {
		return await firebase
			.company(getObjFromLink(claims.companyLink).id)
			.update({
				isArchive: !isArchive,
				dateUpdated: new Date()
			});
	};

	const clientEmailNotificationsHandle = async (value) => {
		return firebase.company(getObjFromLink(claims.companyLink).id).update({
			sendEmailNotificationToClients: value,
			dateUpdated: new Date()
		});
	};

	const adminEmailNotificationsHandle = async (value) => {
		if (value.sendEmailNotificationToAdmin) {
			return firebase.company(getObjFromLink(claims.companyLink).id).update({
				sendEmailNotificationToAdmin: value.sendEmailNotificationToAdmin,
				adminNotificationEmailAddress: value.email,
				dateUpdated: new Date()
			});
		}
		return firebase.company(getObjFromLink(claims.companyLink).id).update({
			sendEmailNotificationToAdmin: value.sendEmailNotificationToAdmin,
			adminNotificationEmailAddress: firebase.firestore.FieldValue.delete(),
			dateUpdated: new Date()
		});
	};
	return (
		<CompanyContext.Provider
			value={{
				company,
				updateCompany,
				archiveCompany,
				clientEmailNotificationsHandle,
				adminEmailNotificationsHandle
			}}
		>
			{children}
		</CompanyContext.Provider>
	);
};

export const CompanyConsumer = CompanyContext.Consumer;

export const useCompany = () => useContext(CompanyContext);
