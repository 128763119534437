import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
	useMemo
} from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { differenceInDays, isSameMonth, sub, subMonths } from 'date-fns';
import { useBrands } from '../brands';
import { useKegs } from '../kegs';

const ReportsContext = createContext({});

const monthsShort = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'June',
	'July',
	'Aug',
	'Sept',
	'Oct',
	'Nov',
	'Dec'
];

export const ReportsProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const { companyKegs } = useKegs();
	const reportNames = [
		{ name: 'reports_issue_kegs', linkType: 'companyLink' },
		{ name: 'reports_keg_movements', linkType: 'companyLink' },
		{ name: 'reports_kegs_dispatched', linkType: 'companyLink' },
		{ name: 'reports_kegs_filled', linkType: 'companyLink' },
		{ name: 'reports_kegs_received', linkType: 'companyLink' },
		{ name: 'reports_kegs_bulk_update', linkType: 'companyLink' },
		{ name: 'reports_warehouse_transfer', linkType: 'companyLink' },
		{ name: 'reports_partner_keg_movements', linkType: 'partnerLink' }
	];
	const [reports, setReports] = useState({
		reports_issue_kegs: undefined,
		reports_keg_movements: undefined,
		reports_kegs_dispatched: undefined,
		reports_kegs_filled: undefined,
		reports_kegs_received: undefined,
		reports_kegs_bulk_update: undefined,
		reports_warehouse_transfer: undefined,
		reports_partner_keg_movements: undefined
	});
	const getReport = useCallback(
		(reportName, linkType) => {
			return firebase
				.reports(reportName)
				.where(linkType, '==', claims?.companyLink)
				.onSnapshot(
					(snap) =>
						setReports((prev) => ({
							...prev,
							[reportName]: transformCollectionSnap(snap)
						})),
					(err) => console.log(err)
				);
		},
		[claims, firebase]
	);

	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_issue_kegs', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_keg_movements', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_kegs_dispatched', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_kegs_filled', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_kegs_received', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_kegs_bulk_update', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_warehouse_transfer', 'companyLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);
	// useEffect(() => {
	// 	return claims?.companyLink
	// 		? getReport('reports_partner_keg_movements', 'partnerLink')
	// 		: () => console.log('noLink');
	// }, [claims, firebase, getReport]);

	const getPartnerReports = (setReports, reportName) => {
		return firebase
			.reports(reportName)
			.where('partnerLink', '==', claims.companyLink)
			.onSnapshot(
				(snap) => setReports(transformCollectionSnap(snap)),
				(err) => console.log(err)
			);
	};

	useEffect(() => console.log('RELOADED'), []);

	const getReports = (setReports, reportName) => {
		return firebase
			.reports(reportName)
			.where('companyLink', '==', claims.companyLink)
			.onSnapshot(
				(snap) => setReports(transformCollectionSnap(snap)),
				(err) => console.log(err)
			);
	};
	const getDispatchedData = useCallback(
		(date) => {
			const monthReports =
				reports.reports_kegs_dispatched?.filter(
					(r) =>
						r.dateDispatched && isSameMonth(r.dateDispatched.toDate(), date)
				) || [];
			const numKegs = monthReports.reduce(
				(tot, report) => tot + (report.kegsDispatched.length || 0),
				0
			);
			return numKegs;
		},
		[reports.reports_kegs_dispatched]
	);
	const getReceiveData = useCallback(
		(date) => {
			const monthReports =
				reports.reports_kegs_received?.filter(
					(r) => r.dateReceived && isSameMonth(r.dateReceived.toDate(), date)
				) || [];
			const numKegs = monthReports.reduce(
				(tot, report) => tot + (report.kegsReceived.length || 0),
				0
			);
			return numKegs;
		},
		[reports.reports_kegs_received]
	);
	const monthData = useCallback(
		(date) => ({
			received: getReceiveData(date),
			dispatched: getDispatchedData(date),
			monthNum: date.getMonth(),
			month: monthsShort[date.getMonth()]
		}),
		[getDispatchedData, getReceiveData]
	);

	const dispatchRecieveCounts = useMemo(() => {
		const thisMonth = monthData(new Date());
		const month1 = monthData(subMonths(new Date(), 1));
		const month2 = monthData(subMonths(new Date(), 2));
		const month3 = monthData(subMonths(new Date(), 3));
		return { thisMonth, month1, month2, month3 };
	}, [monthData]);
	const barData = useMemo(
		() => [
			dispatchRecieveCounts.month3,
			dispatchRecieveCounts.month2,
			dispatchRecieveCounts.month1
		],
		[dispatchRecieveCounts]
	);

	const pieData = useMemo(() => {
		const dispatchReports =
			reports.reports_keg_movements?.filter(
				(r) =>
					r.dateDispatched &&
					(isSameMonth(r.dateDispatched.toDate(), subMonths(new Date(), 1)) ||
						isSameMonth(r.dateDispatched.toDate(), subMonths(new Date(), 2)) ||
						isSameMonth(r.dateDispatched.toDate(), subMonths(new Date(), 3)))
			) || [];
		const brandCounts = dispatchReports.reduce((obj, report) => {
			if (obj[report.kegDetails.brandLink] > 0) {
				return {
					...obj,
					[report.kegDetails.brandLink]: obj[report.kegDetails.brandLink] + 1
				};
			} else {
				return { ...obj, [report.kegDetails.brandLink]: 1 };
			}
		}, {});

		const total = Object.values(brandCounts).reduce(
			(tot, val) => (tot += val),
			0
		);

		let otherCount = 0;
		let numOther = 0;

		return Object.entries(brandCounts)
			.map(([key, value]) => ({
				label: getObjFromLink(key).name,
				id: key,
				value
			}))
			.sort((a, b) => (a.value > b.value ? -1 : 1))
			.reduce((arr, item) => {
				if (item.value / total > 0.025) {
					return [...arr, item];
				} else {
					numOther++;
					const otherItem = arr.find((i) => i.id === 'Other' + otherCount);
					const filtered = arr.filter((i) => i.id !== 'Other' + otherCount);
					if (numOther === 19) {
						otherCount++;
						numOther = 0;
					}
					const other = otherItem
						? {
								...otherItem,
								label: (
									<>
										{otherItem.label}
										<br />
										<span>
											{item.label} <strong>{item.value}</strong>
										</span>
									</>
								),
								value: otherItem.value + item.value
						  }
						: {
								id: 'Other' + otherCount,
								label: (
									<span>
										{item.label} <strong>{item.value}</strong>
									</span>
								),
								value: item.value
						  };

					return [...filtered, other];
				}
			}, [])
			.filter((b) => {
				return b.value > 0;
			})
			.sort((a, b) => (a.value > b.value ? -1 : 1));
	}, [reports.reports_keg_movements]);

	const stockAtClient = useMemo(() => {
		const atClient =
			companyKegs?.filter((k) => k.locationStatus === 'dispatched__client') ||
			[];
		//ADD toDate() BACK WHEN NOT USING DUMMY DATA
		const over30 = atClient.filter((keg) => {
			return (
				differenceInDays(new Date(), keg.dateDispatched.toDate()) > 30 &&
				differenceInDays(new Date(), keg.dateDispatched.toDate()) <= 60
			);
		});
		const over60 = atClient.filter((keg) => {
			return differenceInDays(new Date(), keg.dateDispatched.toDate()) > 60;
		});
		return { atClient, over30, over60 };
	}, [companyKegs]);

	return (
		<ReportsContext.Provider
			value={{
				getPartnerReports,
				getReports,
				...reports,
				barData,
				dispatchRecieveCounts,
				pieData,
				stockAtClient,
				getReport
			}}
		>
			{children}
		</ReportsContext.Provider>
	);
};

export const ReportsConsumer = ReportsContext.Consumer;

export const useReports = () => useContext(ReportsContext);
