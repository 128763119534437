import { getObjFromLink } from '../helpers';

export const formatClientData = (client, companyLink, isNew) => {
	const currentTime = new Date();
	let clientFields = {};

	if (isNew) {
		clientFields = {
			...client,
			dateCreated: currentTime
		};
	} else {
		clientFields = {
			...client,
			dateUpdated: currentTime
		};
	}
	clientFields.companyLink = companyLink;

	clientFields.companyObj = getObjFromLink(companyLink);

	return {
		...clientFields
	};
};
