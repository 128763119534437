import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import './SideDrawer.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import release from '../../../config/release.json';
import NavIcon from '../navIcon';
import { useState } from 'react';
import { useAuth, useUser } from '../../../context/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useHistory } from 'react-router-dom';
import * as icons from '../../ui/icons/';
import {
	faArrowToLeft,
	faArrowToRight
} from '@fortawesome/pro-regular-svg-icons';

const SideDrawer = () => {
	const [showMobileMenu, setShowMobileMenu] = useState(true);
	const history = useHistory();
	const [showMenu, setShowMenu] = useState(true);
	const { claims } = useAuth();
	const profile = useUser();
	const mobileMenuToggleHandler = () => {
		setShowMobileMenu((prev) => !prev);
	};

	return (
		<div
			style={{
				minHeight: '100vh',

				// height: '100vh',
				overflow: 'auto',
				gridColumn: 'span 1 / span 1'

				// display: 'none'
			}}
		>
			{/* MINI DRAWER */}
			<div
				style={{
					backgroundColor: '#1D1D3B',
					height: '100%',
					color: 'white',
					display: !showMenu ? 'flex' : 'none',

					flexDirection: 'column'
				}}
			>
				<div
					style={{
						flex: 1,
						padding: '1rem 0rem',
						display: 'flex',
						flexDirection: 'column',
						fontSize: '1.5rem',
						gap: '3rem'
					}}
				>
					<NavLink
						to={'/dashboard'}
						exact
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faTachometer} />
					</NavLink>
					<NavLink
						to="/kegs-list"
						exact
						onClick={() => {
							// history.push('/kegs-list');
							setShowMenu(true);
						}}
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faDatabase} />
					</NavLink>
					<NavLink
						to="/keg-fill"
						onClick={() => {
							setShowMenu(true);
						}}
						exact
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faTint} />
					</NavLink>
					<div
						onClick={() => {
							setShowMenu(true);
						}}
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faHistory} />
					</div>
					<NavLink
						to="/reports/dash"
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faClipboard} />
					</NavLink>
					<div
						onClick={() => {
							setShowMenu(true);
						}}
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faBuilding} />
					</div>
					<NavLink
						to="/help"
						style={{
							color: 'white',
							padding: '0rem 1rem',
							width: '100%',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<FontAwesomeIcon icon={icons.faLifeRing} />
					</NavLink>
				</div>

				<div
					onClick={() => {
						setShowMenu(true);
					}}
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'end',
						color: 'white',
						padding: '1rem 1rem',
						cursor: 'pointer'
					}}
				>
					<FontAwesomeIcon size="lg" icon={faArrowToRight} />
				</div>
			</div>
			{/* LARGE DRAWER */}
			<div
				style={{
					minHeight: '100vh',
					// transition: 'width 35s ease-in-out',
					// width: showMenu ? 'auto' : '0rem',
					display: showMenu ? 'grid' : 'none'
				}}
				className="side-drawer"
			>
				<div className="side-drawer-header">
					<div className="logo">
						<Link to="/">
							<img
								src="/assets/images/keg-tracker-logo.svg"
								alt="Keg Tracker"
							/>
						</Link>
					</div>
					<div className="mobile-nav-trigger" onClick={mobileMenuToggleHandler}>
						<NavIcon open={showMobileMenu} />
					</div>
				</div>
				<NavigationItems
					profile={profile}
					claims={claims}
					visible={showMobileMenu}
					toggle={mobileMenuToggleHandler}
				/>
				<div
					onClick={() => {
						setShowMenu(false);
					}}
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'end',
						color: 'white',
						padding: '1rem 1rem',
						cursor: 'pointer'
					}}
				>
					<FontAwesomeIcon size="lg" icon={faArrowToLeft} />
				</div>
				<div className="version">Version {release.versionClient}</div>
				<div className="product-of">
					<h3>A product of</h3>
					<a
						href="https://beverageinsights.co"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/assets/images/beverage-insights-logo.svg"
							alt="Beverage Insights"
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default SideDrawer;
