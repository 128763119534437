import { createReducer } from '../../app/common/util/reducerUtil';
import {
	FETCH_CLIENT_KEGS_FOR_DB,
	FETCH_COMPANY_STATS_FOR_DB,
	FETCH_BRAND_STATS,
	FETCH_LOCATION_STATS,
	FETCH_CLIENTS_STATS,
	FETCH_STOCK_TYPE_STATS
} from './dashboardConstants';

const initialState = [];

export const fetchClientKegsForDashboard = (state, payload) => {
	return { ...state, kegs: payload.kegStats };
};

export const fetchCompanyStatsForDashboard = (state, payload) => {
	return { ...state, overview: payload.overviewStats };
};

export const fetchBrandStats = (state, payload) => {
	return { ...state, brands: payload.brandStats };
};
export const fetchWarehouseBrandStats = (state, payload) => {
	return { ...state, warehouseBrands: payload.locationBrandStats };
};
export const fetchClientBrandStats = (state, payload) => {
	return { ...state, clientBrands: payload.clientBrandStats };
};

export const fetchLocationStats = (state, payload) => {
	return { ...state, locations: payload.locationsStats };
};
export const fetchPartnerLocationStats = (state, payload) => {
	return { ...state, partnerLocations: payload.locationsStats };
};
export const fetchAtPartnerLocationStats = (state, payload) => {
	return { ...state, atPartnerLocations: payload.locationsStats };
};

export const fetchClientStats = (state, payload) => {
	return { ...state, clients: payload.clientsStockStats };
};

export const fetchStockTypeStats = (state, payload) => {
	return { ...state, stock: payload.stockStats };
};

export const fetchStatusStats = (state, payload) => {
	return { ...state, status: payload.statusStats };
};

export const fetchCompanyOverview = (state, payload) => {
	return { ...state, overview: payload.overviewStats };
};

export default createReducer(initialState, {
	[FETCH_CLIENT_KEGS_FOR_DB]: fetchClientKegsForDashboard,
	[FETCH_COMPANY_STATS_FOR_DB]: fetchCompanyStatsForDashboard,
	[FETCH_BRAND_STATS]: fetchBrandStats,
	[FETCH_LOCATION_STATS]: fetchLocationStats,
	[FETCH_CLIENTS_STATS]: fetchClientStats,
	[FETCH_STOCK_TYPE_STATS]: fetchStockTypeStats,
	FETCH_STATUS_STATS: fetchStatusStats,
	FETCH_COMPANY_OVERVIEW: fetchCompanyOverview,
	FETCH_PARTNER_LOCATION_STATS: fetchPartnerLocationStats,
	FETCH_AT_PARTNER_LOCATION_STATS: fetchAtPartnerLocationStats,
	FETCH_WAREHOUSE_BRAND_STATS: fetchWarehouseBrandStats,
	FETCH_CLIENT_BRAND_STATS: fetchClientBrandStats
});
