import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageHeader.css';
import { Dropdown, Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import { getObjFromLink } from '../../../context/helpers';
import { useAuth, useUser } from '../../../context/auth';
import { useCompanies } from '../../../context/companies';
import { useFirebase } from '../../../context/Firebase';

const TopMenu = styled(Menu)`
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	.item {
		color: #fff !important;
	}
	.item::before {
		width: 0px;
		background: transparent !important;
	}
`;

const PageHeader = ({ title }) => {
	const { updateCompany, claims, clearData } = useAuth();
	const { companies } = useCompanies();
	const user = useUser();
	const firebase = useFirebase();

	return (
		<header className="app-header">
			<TopMenu>
				<Menu.Item>
					<h1 className="page-title">{title}</h1>
				</Menu.Item>
				<Menu.Menu position="right" className="menu-comp-selector">
					{claims?.isAdmin && (
						<Dropdown
							item
							text={
								claims?.companyLink
									? getObjFromLink(claims.companyLink).name
									: 'Select a Company'
							}
						>
							<Dropdown.Menu>
								{companies?.map((c, idx) => {
									return (
										<Dropdown.Item
											key={idx}
											className={
												c.id === getObjFromLink(claims.companyLink).id
													? 'active'
													: ''
											}
											onClick={() => updateCompany(`${c.id}__${c.name}`)}
										>
											{c.name}
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					)}
					{user && claims?.isDistributor && (
						<Dropdown
							item
							text={
								claims?.companyLink
									? getObjFromLink(claims.companyLink).name
									: 'Select a Company'
							}
						>
							<Dropdown.Menu>
								{user.companyObjs?.map((c, idx) => {
									return (
										<Dropdown.Item
											key={idx}
											className={
												c.id === getObjFromLink(claims.companyLink).id
													? 'active'
													: ''
											}
											onClick={() => updateCompany(`${c.id}__${c.name}`)}
										>
											{c.name}
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					)}
					{/* </Menu.Menu>

				<Menu.Menu position={claims?.isAdmin ? 'left' : 'right'}> */}
					<Dropdown item text={user?.email}>
						<Dropdown.Menu>
							<Dropdown.Item
								as={NavLink}
								to={`/settings`}
								text="Settings"
								icon="settings"
							/>
							<Dropdown.Item
								icon="power"
								text="Logout"
								onClick={() => {
									firebase.signOut();
									clearData();
								}}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</Menu.Menu>
			</TopMenu>
		</header>
	);
};

export default PageHeader;
