import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFirestore } from 'react-redux-firebase';
import release from '../../config/release.json';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';
import { useFirebase } from '../../context/Firebase';
import { transformDocSnap } from '../../context/helpers';
import { set } from 'lodash';

export default function CheckVersion() {
	const [show, setShow] = useState(false);
	const [dbVersion, setDbVersion] = useState(false);
	const firebase = useFirebase();
	useEffect(() => {
		const unsubscribe = firebase.db
			.collection('settings')
			.doc('release')
			.onSnapshot((snap) => {
				const dbrelease = transformDocSnap(snap);
				setDbVersion(dbrelease.versionClient);
				setShow(release.versionClient !== dbrelease.versionClient);
			});
		return () => unsubscribe();
	}, []);

	return show ? (
		<CheckVersionInfo>
			<h2>New Version Available</h2>
			<p>
				A new version ({dbVersion}) of KegTracker is available and you need to
				reload this page to see the latest changes.
			</p>
			<Button
				basic
				fluid
				onClick={() => {
					window.location.reload(true);
				}}
			>
				Get latest version <Icon name="refresh" />
			</Button>
			<div style={{ height: '10px' }} />
			<Button
				basic
				fluid
				onClick={() => {
					setShow(false);
				}}
			>
				Dismiss Warning
			</Button>
		</CheckVersionInfo>
	) : null;
}

const CheckVersionInfo = styled.div`
	position: fixed;
	top: 90px;
	right: 20px;
	z-index: 999;
	width: 320px;
	background: #2b2c50;
	padding: 30px;
	box-shadow: 0 3px 8px 0 rgba(145, 145, 145, 0.17);
	border-radius: 4px;
	h2 {
		color: #fff;
	}
	p {
		color: #fff;
	}
	.icon {
		padding-left: 10px;
	}
	.ui.button {
		text-transform: uppercase;
		background-color: #fbbd08 !important;
		border-color: #fbbd08 !important;
		color: #191935 !important;
		&:hover {
			color: #191935 !important;
			background-color: #fbbd08 !important;
		}
	}
`;
