import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { useKegs } from '../kegs';
import { formatClientData } from './formatting';

const ClientsContext = createContext({});

export const ClientsProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const [clients, setClients] = useState();

	const { companyKegs } = useKegs();

	useEffect(() => {
		return claims?.companyLink
			? firebase.clients(getObjFromLink(claims.companyLink).id).onSnapshot(
					(snap) => {
						setClients(
							transformCollectionSnap(snap).filter(
								(client) => !client.isArchive
							)
						);
					},
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);

	const createClient = async (client) => {
		const newClient = formatClientData(client, claims.companyLink, true);

		return await firebase
			.clients(getObjFromLink(claims.companyLink).id)
			.add(newClient);
	};
	const updateClient = async (client, id) => {
		const updatedClient = formatClientData(client, claims.companyLink);
		let batch = firebase.db.batch();
		// Update Company Profile
		let clientDocRef = firebase
			.clients(getObjFromLink(claims.companyLink).id)
			.doc(id);
		await batch.update(clientDocRef, updatedClient);
		// New Client Data for linked assets
		const newClientData = {
			clientLink: `${client.id}__${client.name}`,
			clientObj: { name: client.name, id: client.id }
		};
		// Update Kegs linked to client
		const kegsQuerySnap = await firebase.db
			.collection('kegs')
			.where('clientObj.id', '==', id)
			.where('isArchive', '==', false)
			.get();
		const kegs = transformCollectionSnap(kegsQuerySnap);

		kegs.forEach((k) =>
			batch.update(firebase.db.collection('kegs').doc(k.id), newClientData)
		);

		// end client
		await batch.commit();
	};

	const archiveClient = async (isArchive, id) => {
		return await firebase
			.clients(getObjFromLink(claims.companyLink).id)
			.doc(id)
			.update({
				isArchive: !isArchive,
				dateUpdated: new Date()
			});
	};

	const clientStats = useMemo(() => {
		// Stock with Clients
		const stockWithClients = companyKegs
			?.filter((k) => !k.isArchived)
			.filter(
				(item) => item.clientLink && item && item.kegStatus === 'dispatched'
			);

		if (clients && companyKegs) {
			return clients
				?.map((client) => {
					// Stock Type = Fridge
					const stockTypeKeg = stockWithClients.filter(
						(item) => item.clientObj.id === client.id && item.type === 'keg'
					);
					const stockTypeGas = stockWithClients.filter(
						(item) => item.clientObj.id === client.id && item.type === 'gas'
					);
					const stockTypeFridges = stockWithClients.filter(
						(item) => item.clientObj.id === client.id && item.type === 'fridge'
					);
					const allClientStock = stockWithClients.filter(
						(item) => item.clientObj.id === client.id
					);
					// Profile Completed Score
					let score = 0;
					if (client.name) {
						score = score + 5;
					}
					if (client.email) {
						score = score + 5;
					}
					if (client.phone) {
						score = score + 5;
					}
					if (client.website) {
						score = score + 5;
					}
					if (client.address_line1) {
						score = score + 10;
					}
					if (client.address_line2) {
						score = score + 5;
					}
					if (client.address_suburb) {
						score = score + 10;
					}
					if (client.address_city) {
						score = score + 10;
					}
					if (client.address_province) {
						score = score + 10;
					}
					if (client.address_postalCode) {
						score = score + 5;
					}
					if (client.gps_lat) {
						score = score + 1;
					}
					if (client.gps_lng) {
						score = score + 1;
					}
					if (client.contact_person_name) {
						score = score + 10;
					}
					if (client.contact_person_position) {
						score = score + 2;
					}
					if (client.contact_person_email) {
						score = score + 1;
					}
					if (client.contact_person_phone) {
						score = score + 10;
					}
					if (client.contact_person_mobile) {
						score = score + 5;
					}

					// Build Client Object to return
					return {
						id: client.id,
						name: client.name,
						countAllStock: allClientStock.length,
						countKegs: stockTypeKeg.length,
						kegs: stockTypeKeg,
						countGas: stockTypeGas.length,
						gas: stockTypeGas,
						countFridges: stockTypeFridges.length,
						fridges: stockTypeFridges,
						score
					};
				})
				.sort((a, b) => (a.name > b.name ? 1 : -1));
		}
	}, [clients, companyKegs]);

	return (
		<ClientsContext.Provider
			value={{
				clients,
				createClient,
				updateClient,
				archiveClient,
				clientStats
			}}
		>
			{children}
		</ClientsContext.Provider>
	);
};

export const ClientsConsumer = ClientsContext.Consumer;

export const useClients = () => useContext(ClientsContext);
