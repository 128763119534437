import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CheckVersion from '../common/util/CheckVersion';
import SideDrawer from './navigation/SideDrawer/SideDrawer';
import DataProvider from '../context/data';
import Loadable from 'react-loadable';
import LoadingComponent from './LoadingComponent';
import { useAuth } from '../context/auth';
import AnounceMent from '../common/anouncement';
import Dashboard from '../../features/dashboard';
import Map from '../../features/map/index';

// const AsyncKegCreate = Loadable({
// 	loader: () => import('../../features/kegs/kegCreate'),
// 	loading: LoadingComponent
// });
const AsyncKegFill = Loadable({
	loader: () => import('../../features/kegs/kegFill'),
	loading: LoadingComponent
});
const AsyncWarehouseTransfer = Loadable({
	loader: () => import('../../features/stockControl/warehouseTransfer'),
	loading: LoadingComponent
});
// const AsyncKegs = Loadable({
// 	loader: () => import('../../features/kegs'),
// 	loading: LoadingComponent
// });
const AsyncKegList = Loadable({
	loader: () => import('../../features/kegs/kegList'),
	loading: LoadingComponent
});
const AsyncKegDetailedPage = Loadable({
	loader: () => import('../../features/kegs/KegDetailedPage/KegDetailedPage'),
	loading: LoadingComponent
});
const AsyncKegBatchUpdatePage = Loadable({
	loader: () => import('../../features/kegs/KegBatchUpdate/KegBatchUpdate'),
	loading: LoadingComponent
});
const AsyncKegQuickViewPage = Loadable({
	loader: () => import('../../features/kegs/KegQuickView/KegQuickView'),
	loading: LoadingComponent
});

const AsyncInspectionPilePage = Loadable({
	loader: () => import('../../features/kegs/InspectionPile'),
	loading: LoadingComponent
});
const AsyncOnboardKegsPage = Loadable({
	loader: () => import('../../features/kegs/OnboardKegs'),
	loading: LoadingComponent
});
const AsyncFixKegPage = Loadable({
	loader: () => import('../../features/kegs/InspectionPile/Fix'),
	loading: LoadingComponent
});
const AsyncIssueKegPage = Loadable({
	loader: () => import('../../features/kegs/InspectionPile/Issue'),
	loading: LoadingComponent
});

const AsyncHelp = Loadable({
	loader: () => import('../../features/help'),
	loading: LoadingComponent
});
const AsyncDispatch = Loadable({
	loader: () => import('../../features/stockControl/dispatch'),
	loading: LoadingComponent
});
const AsyncReceive = Loadable({
	loader: () => import('../../features/stockControl/receive'),
	loading: LoadingComponent
});
const AsyncReports = Loadable({
	loader: () => import('../../features/reports'),
	loading: LoadingComponent
});
// const AsyncUsers = Loadable({
// 	loader: () => import('../../features/users'),
// 	loading: LoadingComponent
// });
// const AsyncCompanies = Loadable({
// 	loader: () => import('../../features/companies'),
// 	loading: LoadingComponent
// });
const AsyncCompanyDetailedPage = Loadable({
	loader: () =>
		import('../../features/companies/CompanyDetailedPage/CompanyDetailedPage'),
	loading: LoadingComponent
});
const AsyncSettings = Loadable({
	loader: () => import('../../features/settings'),
	loading: LoadingComponent
});
// const AsyncUserDetailedPage = Loadable({
// 	loader: () =>
// 		import('../../features/users/UserDetailedPage/UserDetailedPage'),
// 	loading: LoadingComponent
// });
// const AsyncContacts = Loadable({
// 	loader: () => import('../../features/contacts'),
// 	loading: LoadingComponent
// });
// const AsyncContactDetailedPage = Loadable({
// 	loader: () =>
// 		import('../../features/contacts/ContactDetailedPage/ContactDetailedPage'),
// 	loading: LoadingComponent
// });
// const AsyncCreateUser = Loadable({
// 	loader: () => import('../../features/users/CreateUser/CreateUser'),
// 	loading: LoadingComponent
// });
// const AsyncCreateCompany = Loadable({
// 	loader: () => import('../../features/companies/CreateCompany/CreateCompany'),
// 	loading: LoadingComponent
// });
// const AsyncCreateContact = Loadable({
// 	loader: () => import('../../features/contacts/CreateContact/CreateContact'),
// 	loading: LoadingComponent
// });
const AsyncBatchCompletePage = Loadable({
	loader: () => import('../../features/kegs/BatchComplete/BatchComplete'),
	loading: LoadingComponent
});
const AsyncRecentActivityLog = Loadable({
	loader: () => import('../../features/activity'),
	loading: LoadingComponent
});
const AsyncCompanyUsers = Loadable({
	loader: () => import('../../features/company-users/index'),
	loading: LoadingComponent
});
const AsyncMostFilledKegs = Loadable({
	loader: () => import('../../features/kegs/MostFilledKegs/index.jsx'),
	loading: LoadingComponent
});
// const AsyncStatsPage = Loadable({
// 	loader: () => import('../../features/stats'),
// 	loading: LoadingComponent
// });
const AsyncAllActionsPage = Loadable({
	loader: () => import('../../features/reports/allactions/index'),
	loading: LoadingComponent
});

const authRoutes = [];
const createRoutes = (routes) => {
	return routes.map((route, idx) => (
		<Route exact path={route.path} component={route.component} key={idx} />
	));
};

export default function Router() {
	const { claims } = useAuth();
	return (
		<DataProvider>
			{!claims?.companyLink ? (
				<LoadingComponent />
			) : (
				<div
					style={{
						minHeight: '100vh',
						// height: '100vh',
						overflowY: 'auto',
						gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
						display: 'flex'
					}}
					className="app-container"
				>
					<SideDrawer />
					<div
						style={{
							height: '100vh',
							overflowY: 'auto',
							gridColumn: 'span 11 / span 11',
							width: '100%'
						}}
						className="page-content"
					>
						<CheckVersion />
						<Switch>
							<Route path="/dashboard" component={Dashboard} />
							<Route path="/map" component={Map} />

							<Route path="/keg-fill" exact component={AsyncKegFill} />
							<Route
								path="/warehouse-transfer"
								exact
								component={AsyncWarehouseTransfer}
							/>
							<Route exact path="/kegs-list" component={AsyncKegList} />
							<Route
								exact
								path="/most-filled"
								component={AsyncMostFilledKegs}
							/>
							<Route
								exact
								path="/all-actions"
								component={AsyncAllActionsPage}
							/>
							<Route exact path="/kegs-list/:status" component={AsyncKegList} />
							<Route path="/kegs/:id" exact component={AsyncKegDetailedPage} />
							<Route
								path="/keg-batch-update"
								exact
								component={AsyncKegBatchUpdatePage}
							/>

							<Route
								path="/inspection-pile"
								exact
								component={AsyncInspectionPilePage}
							/>
							<Route
								path="/onboard-kegs"
								exact
								component={AsyncOnboardKegsPage}
							/>
							<Route path="/fix/:id" exact component={AsyncFixKegPage} />
							<Route path="/issue/:id" exact component={AsyncIssueKegPage} />
							<Route
								path="/keg-quick-view"
								exact
								component={AsyncKegQuickViewPage}
							/>
							<Route path="/dispatch" exact component={AsyncDispatch} />
							<Route path="/receive" exact component={AsyncReceive} />
							<Route path="/reports" component={AsyncReports} />
							<Route path="/help" exact component={AsyncHelp} />
							<Route path="/settings" component={AsyncSettings} />
							<Route path="/companies" component={AsyncCompanyDetailedPage} />
							<Route path="/companies/users" component={AsyncCompanyUsers} />

							<Route
								path="/batch-complete"
								exact
								component={AsyncBatchCompletePage}
							/>
							<Route
								path="/activity-log/:collection/:id"
								exact
								component={AsyncRecentActivityLog}
							/>
							<Redirect to="/" />
						</Switch>
					</div>
					<AnounceMent />
				</div>
			)}
		</DataProvider>
	);
}
