import React, { useMemo } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { useAuth } from '../../../app/context/auth';
import { useKegs } from '../../../app/context/kegs';
import SimpleLoader from '../../../app/layout/SimpleLoader';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict/index';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { differenceInDays, differenceInMonths, fromUnixTime } from 'date-fns';

const now = new Date();

const KegTurnTime = () => {
	const { claims } = useAuth();
	const { companyKegs, partnerKegs } = useKegs();
	const dispatchedkegs = useMemo(() => {
		if (companyKegs && partnerKegs) {
			const less1month = [...companyKegs, ...partnerKegs]
				.filter((keg) => !keg.isArchive)
				.filter((keg) => keg.type === 'keg')
				.filter((keg) => {
					const dateDispatched = keg.dateDispatched?.toDate();
					const diffInMonths = differenceInMonths(now, dateDispatched);
					const diffInDays = differenceInDays(now, dateDispatched);
					return diffInDays < 30;
				})
				.filter((keg) => {
					if (keg.partnerLink === claims.companyLink) {
						return (
							keg.locationStatus === 'dispatched__client' ||
							keg.locationStatus === 'dispatched__partner' ||
							keg.locationStatus === 'receive__partner'
						);
					}

					return (
						(keg.partnerLink !== claims.companyLink &&
							keg.locationStatus === 'dispatched__partner') ||
						keg.locationStatus === 'in_warehouse__partner' ||
						keg.locationStatus === 'receive__partner' ||
						keg.locationStatus === 'dispatched__client'
					);
				}).length;
			const sixMonth = [...companyKegs, ...partnerKegs]
				.filter((keg) => !keg.isArchive)
				.filter((keg) => keg.type === 'keg')
				.filter((keg) => {
					const dateDispatched = keg.dateDispatched?.toDate();
					const diffInMonths = differenceInMonths(now, dateDispatched);
					const diffInDays = differenceInDays(now, dateDispatched);
					return diffInMonths > 6;
				})
				.filter((keg) => {
					if (keg.partnerLink === claims.companyLink) {
						return (
							keg.locationStatus === 'dispatched__client' ||
							keg.locationStatus === 'dispatched__partner' ||
							keg.locationStatus === 'receive__partner'
						);
					}

					return (
						(keg.partnerLink !== claims.companyLink &&
							keg.locationStatus === 'dispatched__partner') ||
						keg.locationStatus === 'in_warehouse__partner' ||
						keg.locationStatus === 'receive__partner' ||
						keg.locationStatus === 'dispatched__client'
					);
				}).length;
			const twoMonths = [...companyKegs, ...partnerKegs]
				.filter((keg) => !keg.isArchive)
				.filter((keg) => keg.type === 'keg')
				.filter((keg) => {
					const dateDispatched = keg.dateDispatched?.toDate();
					const diffInMonths = differenceInMonths(now, dateDispatched);
					const diffInDays = differenceInDays(now, dateDispatched);
					return diffInMonths >= 1 && diffInMonths <= 2;
				})
				.filter((keg) => {
					if (keg.partnerLink === claims.companyLink) {
						return (
							keg.locationStatus === 'dispatched__client' ||
							keg.locationStatus === 'dispatched__partner' ||
							keg.locationStatus === 'receive__partner'
						);
					}

					return (
						(keg.partnerLink !== claims.companyLink &&
							keg.locationStatus === 'dispatched__partner') ||
						keg.locationStatus === 'in_warehouse__partner' ||
						keg.locationStatus === 'receive__partner' ||
						keg.locationStatus === 'dispatched__client'
					);
				}).length;
			console.log(twoMonths, '222');
			const twoToSixMonths = [...companyKegs, ...partnerKegs]
				.filter((keg) => !keg.isArchive)
				.filter((keg) => keg.type === 'keg')
				.filter((keg) => {
					const dateDispatched = keg.dateDispatched?.toDate();
					const diffInMonths = differenceInMonths(now, dateDispatched);
					const diffInDays = differenceInDays(now, dateDispatched);
					return diffInMonths >= 2 && diffInMonths <= 6;
				})
				.filter((keg) => {
					if (keg.partnerLink === claims.companyLink) {
						return (
							keg.locationStatus === 'dispatched__client' ||
							keg.locationStatus === 'dispatched__partner' ||
							keg.locationStatus === 'receive__partner'
						);
					}

					return (
						(keg.partnerLink !== claims.companyLink &&
							keg.locationStatus === 'dispatched__partner') ||
						keg.locationStatus === 'in_warehouse__partner' ||
						keg.locationStatus === 'receive__partner' ||
						keg.locationStatus === 'dispatched__client'
					);
				}).length;
			return { less1month, sixMonth, twoMonths, twoToSixMonths };
		}
		return 0;
	}, [companyKegs, partnerKegs, claims]);

	return claims?.companyLink ? (
		<div className="widget margin-bottom">
			<div className="widget-table-header">
				<h2>Average turn time</h2>
				{/* <Button size="mini" as={Link} to={`/most-filled`}>
					View all
					<Icon name="long arrow alternate right" />
				</Button> */}
			</div>
			{companyKegs ? (
				companyKegs.length !== 0 ? (
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(4, 1fr)',
							height: '100%',
							alignItems: 'center',
							marginTop: '2rem',
							gap: '2rem'
						}}
					>
						<NavLink
							style={{ color: 'black' }}
							to="kegs-list/dispatched?period=less1month"
						>
							<div
								style={{
									// boxShadow: '0px 0px 2px  rgba(0,0,0,0.3)',
									borderRadius: '8px',

									overflow: 'hidden'
								}}
							>
								<div
									style={{
										backgroundColor: '#F6F9FC',
										padding: '2rem',
										justifyContent: 'center',
										alignItems: 'center',
										display: 'flex',
										fontWeight: 'bold',
										fontSize: '3em'
									}}
								>
									{dispatchedkegs.less1month}
								</div>
								<div
									style={{
										backgroundColor: '#B4CC17',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: 'white',
										padding: '8px 8px',
										fontWeight: 'bold'
									}}
								>
									Less Than 1 Month
								</div>
							</div>
						</NavLink>
						<NavLink
							style={{ color: 'black' }}
							to="kegs-list/dispatched?period=1-2months"
						>
							<div
								style={{
									// boxShadow: '0px 0px 2px  rgba(0,0,0,0.3)',
									borderRadius: '8px',
									overflow: 'hidden'
								}}
							>
								<div
									style={{
										backgroundColor: '#F6F9FC',
										padding: '2rem',
										justifyContent: 'center',
										alignItems: 'center',
										display: 'flex',
										fontWeight: 'bold',
										fontSize: '3em'
									}}
								>
									{dispatchedkegs.twoMonths}
								</div>
								<div
									style={{
										backgroundColor: '#01B5AD',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: 'white',
										padding: '8px 8px',
										fontWeight: 'bold'
									}}
								>
									1-2 Months
								</div>
							</div>
						</NavLink>
						<NavLink
							style={{ color: 'black' }}
							to="kegs-list/dispatched?period=2-6months"
						>
							<div
								style={{
									// boxShadow: '0px 0px 2px  rgba(0,0,0,0.3)',
									borderRadius: '8px',
									overflow: 'hidden'
								}}
							>
								<div
									style={{
										backgroundColor: '#F6F9FC',
										padding: '2rem',
										justifyContent: 'center',
										alignItems: 'center',
										display: 'flex',
										fontWeight: 'bold',
										fontSize: '3em'
									}}
								>
									{dispatchedkegs.twoToSixMonths}
								</div>
								<div
									style={{
										backgroundColor: '#FABC0A',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: 'white',
										padding: '8px 8px',
										fontWeight: 'bold'
									}}
								>
									2-6 Months
								</div>
							</div>
						</NavLink>
						<NavLink
							style={{ color: 'black' }}
							to="kegs-list/dispatched?period=6months"
						>
							<div
								style={{
									// boxShadow: '0px 0px 2px  rgba(0,0,0,0.3)',
									borderRadius: '8px',
									overflow: 'hidden'
								}}
							>
								<div
									style={{
										backgroundColor: '#F6F9FC',
										padding: '2rem',
										justifyContent: 'center',
										alignItems: 'center',
										display: 'flex',
										fontWeight: 'bold',
										fontSize: '3em'
									}}
								>
									{dispatchedkegs.sixMonth}
								</div>
								<div
									style={{
										backgroundColor: '#DA2828',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: 'white',
										padding: '8px 8px',
										fontWeight: 'bold'
									}}
								>
									6+ Months
								</div>
							</div>
						</NavLink>
					</div>
				) : (
					<div className="no-records-found-illustration-wrapper">
						<img src="/assets/illustrations/empty.svg" alt="Report Empty" />
						<p>No Kegs Found...</p>
					</div>
				)
			) : (
				<div className="no-records-found-illustration-wrapper">
					<SimpleLoader color="grey" />
					<p>Fetching kegs...</p>
				</div>
			)}
		</div>
	) : null;
};

export default KegTurnTime;
