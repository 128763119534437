import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Label, Button, Icon, Message } from 'semantic-ui-react';
import StatusLabel from '../../../sharedComponents/StatusLabel';
import TypeLabel from '../../../sharedComponents/TypeLabel';
import format from 'date-fns/format';
import compareDesc from 'date-fns/compare_desc';
import LocationStatusLabel from '../../../sharedComponents/LocationStatusLabel';

const KegToQuickViewDetails = ({ keg, emptyKeg, archiveKeg }) => {
	if (!keg) return <p />;

	const sortedDates = () => {
		const values = [
			'dateUpdated',
			'dateUpdated',
			'dateReceived',
			'dateDispatched',
			'dateFilled',
			'dateCreated'
		];
		const kegInfoArray = Object.keys(keg).map(function(key) {
			return { propName: key, value: keg[key] };
		});

		const kegDatesArray = kegInfoArray
			.filter((k) => {
				return values.includes(k.propName);
			})
			.map((k) => {
				return {
					...k,
					label: k.propName.replace('date', 'Date '),
					date: k.value.toDate()
				};
			})
			.sort(function(a, b) {
				return compareDesc(a.date, b.date);
			});
		return kegDatesArray;
	};

	const dateRows = () => {
		const dates = sortedDates();
		const rows = [];
		dates.forEach((d, idx) => {
			rows.push(
				<Table.Row key={idx}>
					<Table.Cell>
						<Label horizontal className="pull-right">
							{d.label}
						</Label>
						<strong>{format(d.value.toDate(), 'DD MMM YYYY | HH:mm')}</strong>
					</Table.Cell>
				</Table.Row>
			);
		});
		return rows;
	};

	return (
		<span>
			{keg.isArchive ? (
				<Message>This keg is archived, it can be reactived below </Message>
			) : null}

			<Table unstackable striped size="large">
				<Table.Body>
					<Table.Row>
						<Table.Cell>
							{keg.isArchive ? <Icon name="archive" /> : null}{' '}
							<strong>{keg.id}</strong>
							<Label horizontal className="pull-right">
								Keg ID
							</Label>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								New
							</Label>
							{keg.isNew ? (
								<Icon name="check circle" color="yellow" />
							) : (
								<Icon name="times circle" color="grey" />
							)}
						</Table.Cell>
					</Table.Row>
					{keg.serialNumber && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Serial Number
								</Label>
								<strong>{keg.serialNumber}</strong>
							</Table.Cell>
						</Table.Row>
					)}
					{keg.clientObj && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Client
								</Label>
								<strong>{keg.clientObj && keg.clientObj.name}</strong>
							</Table.Cell>
						</Table.Row>
					)}
					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								Filled
							</Label>
							{keg.isFilled ? (
								<Icon name="check circle" color="green" />
							) : (
								<Icon name="times circle" color="red" />
							)}
						</Table.Cell>
					</Table.Row>
					{keg.brandObj && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Brand
								</Label>
								<strong>{keg.brandObj && keg.brandObj.name}</strong>
							</Table.Cell>
						</Table.Row>
					)}
					{keg.locationStatus && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Location
								</Label>
								<LocationStatusLabel locationStatus={keg.locationStatus} icon />
							</Table.Cell>
						</Table.Row>
					)}

					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								Stock Type
							</Label>
							<TypeLabel horizontal type={keg.type && keg.type} />
						</Table.Cell>
					</Table.Row>
					{keg.style && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Style
								</Label>
								<strong>{keg.style}</strong>
							</Table.Cell>
						</Table.Row>
					)}
					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								Company
							</Label>
							<strong>{keg.companyObj && keg.companyObj.name}</strong>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								Warehouse
							</Label>
							<strong>{keg.locationObj && keg.locationObj.name}</strong>
							<p>{keg.gps && `(${keg.gps.lat}, ${keg.gps.lng})`}</p>
						</Table.Cell>
					</Table.Row>
					{keg.volume && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Volume
								</Label>
								<strong>{keg.volume} ℓ</strong>
							</Table.Cell>
						</Table.Row>
					)}
					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								Coupler
							</Label>
							<strong>{keg.coupler && keg.coupler.toUpperCase()}</strong>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							<Label horizontal className="pull-right">
								Last worked on by
							</Label>
							<strong>{keg.workedOnBy && keg.workedOnBy.name}</strong>
						</Table.Cell>
					</Table.Row>
					{keg.lastFillBrandObj && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Last Filled with
								</Label>
								<strong>{keg.lastFillBrandObj.name}</strong>
							</Table.Cell>
						</Table.Row>
					)}
					{keg.partnerCode && (
						<Table.Row>
							<Table.Cell>
								<Label horizontal className="pull-right">
									Share with Partner?
								</Label>
								<strong>{keg.partnerCode}</strong>
							</Table.Cell>
						</Table.Row>
					)}
					{dateRows()}
					<Table.Row textAlign="center">
						{keg.isArchive ? (
							<Table.Cell>
								<Button
									onClick={() => {
										archiveKeg(keg.isArchive, keg);
									}}
								>
									<Icon name="reply" /> Reactivate Keg
								</Button>
							</Table.Cell>
						) : (
							<Table.Cell>
								<Button icon as={Link} to={`/kegs/${keg.id}`}>
									<Icon name="pencil" /> Edit Keg
								</Button>
								{keg.kegStatus === 'issue' ? (
									<Button as={Link} to={`/fix/${keg.id}`} icon>
										<Icon name="wrench" /> Fix Keg
									</Button>
								) : (
									<Button as={Link} to={`/issue/${keg.id}`} icon>
										<Icon name="exclamation triangle" /> Mark Keg as Issue
									</Button>
								)}
								{(keg.kegStatus === 'warehouse' || keg.isFilled) && (
									<Button onClick={() => emptyKeg(keg)}>
										<Icon name="arrow alternate circle down" /> Empty Keg
									</Button>
								)}
							</Table.Cell>
						)}
					</Table.Row>
				</Table.Body>
			</Table>
		</span>
	);
};
export default KegToQuickViewDetails;
