import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Table, Popup, Progress } from 'semantic-ui-react';
import { getClientsStats } from '../dashboardActions';
import { Link } from 'react-router-dom';
import { useClients } from '../../../app/context/clients';
import { getObjFromLink } from '../../../app/context/helpers';
import { useAuth } from '../../../app/context/auth';
import SimpleLoader from '../../../app/layout/SimpleLoader';

const DashboardClientsStats = () => {
	const { clientStats } = useClients();
	const { claims } = useAuth();

	console.log(clientStats, 'f');
	return claims?.companyLink ? (
		<div className="widget margin-bottom">
			<div className="widget-table-header">
				<h2>Stock at Clients</h2>
				<Button size="mini" as={Link} to={`/companies/clients`}>
					All Clients
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
			{clientStats ? (
				clientStats.filter((client) => client.countAllStock > 0).length !==
				0 ? (
					<Table
						basic="very"
						compact="very"
						celled
						collapsing
						className="brands-table"
					>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell textAlign="center">Kegs</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">Gas</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">Fridges</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Profile %
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{clientStats &&
								clientStats
									.filter(
										(client) =>
											client.countKegs > 0 ||
											client.countGas > 0 ||
											client.countFridges > 0
									)
									.map((client) => (
										<Table.Row key={client.id}>
											<Table.Cell className="label">
												<Link
													to={`/companies/clients/data/${client.id}`}
													className="dashboard-client-link"
												>
													{client.name}
												</Link>
											</Table.Cell>
											<Table.Cell textAlign="center">
												{client.countKegs > 0 ? (
													<Popup
														trigger={
															<p className="dashboard-keg-count-trigger">
																{client.countKegs}
															</p>
														}
														content={
															<div className="keg-count-label-wrapper">
																{client.kegs.map((keg) => (
																	<span
																		className={`${client.countKegs < 5 &&
																			'cell-span'} keg-count-label`}
																		key={keg.label}
																	>
																		{keg.label}
																	</span>
																))}
															</div>
														}
														inverted
														position="top center"
													/>
												) : (
													client.countKegs
												)}
											</Table.Cell>
											<Table.Cell textAlign="center">
												{client.countGas > 0 ? (
													<Popup
														trigger={
															<p className="dashboard-keg-count-trigger">
																{client.countGas}
															</p>
														}
														content={
															<div className="keg-count-label-wrapper">
																{client.gas.map((g) => (
																	<span
																		className={`${client.countGas < 5 &&
																			'cell-span'} keg-count-label`}
																		key={g.label}
																	>
																		{g.label}
																	</span>
																))}
															</div>
														}
														position="top center"
														inverted
													/>
												) : (
													client.countGas
												)}
											</Table.Cell>
											<Table.Cell textAlign="center">
												{client.countFridges > 0 ? (
													<Popup
														trigger={
															<p className="dashboard-keg-count-trigger">
																{client.countFridges}
															</p>
														}
														content={
															<div className="keg-count-label-wrapper">
																{client.fridges.map((fridge) => (
																	<span
																		className={`${client.countFridges < 5 &&
																			'cell-span'} keg-count-label`}
																		key={fridge.label}
																	>
																		{fridge.label}
																	</span>
																))}
															</div>
														}
														position="top center"
														inverted
													/>
												) : (
													client.countFridges
												)}
											</Table.Cell>
											<Table.Cell textAlign="center">
												<div className="dashboard-client-progress-wrapper">
													<Popup
														trigger={
															<Progress
																percent={client.score}
																size="tiny"
																color="yellow"
															/>
														}
														content={<p>{client.score}% Complete</p>}
														position="left center"
														inverted
													/>
												</div>
											</Table.Cell>
										</Table.Row>
									))}
						</Table.Body>
					</Table>
				) : (
					<div className="no-records-found-illustration-wrapper">
						<img src="/assets/illustrations/empty.svg" alt="Report Empty" />
						<p>No clients found for report...</p>
					</div>
				)
			) : (
				<div className="no-records-found-illustration-wrapper">
					<SimpleLoader color="grey" />
					<p>Fetching clients...</p>
				</div>
			)}
		</div>
	) : null;
};
export default DashboardClientsStats;
