import React, { createContext, useContext, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const MapContext = createContext(null);
const libraries = ['places'];
const MapContextProvider = ({ children }) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries: libraries
	});
	return (
		<MapContext.Provider value={{ isMapLoaded: isLoaded }}>
			{children}
		</MapContext.Provider>
	);
};

export default MapContextProvider;
export const useMap = () => useContext(MapContext);
