import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Divider, Icon, Table } from 'semantic-ui-react';
import { useUser, useAuth } from '../../../app/context/auth';

const DashboardWelcomeWidget = () => {
	const { claims } = useAuth();
	return (
		<div className="widget quick-links">
			<h2>Welcome to Keg Tracker</h2>
			<Divider />
			<Grid>
				<Grid.Row columns={3}>
					{!claims?.isWarehouse && (
						<Grid.Column>
							<h3>Get Started</h3>
							<Table basic="very">
								<Table.Body>
									<Table.Row>
										<Table.Cell>
											<Link to={`/companies/details`}>
												<Icon name="building outline" color="grey" />
												Edit your company profile
											</Link>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											<Link to="/settings">
												<Icon name="user circle" color="grey" />
												Edit your user profile
											</Link>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											<Link to="/settings/account">
												<Icon name="key" color="grey" />
												Change your password
											</Link>
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						</Grid.Column>
					)}
					{!claims?.isWarehouse && (
						<Grid.Column>
							<h3>Next Steps</h3>
							<Table basic="very">
								<Table.Body>
									<Table.Row>
										<Table.Cell>
											<Link to={`/companies/location-create`}>
												<Icon name="map marker alternate" color="grey" />
												<Icon name="plus" color="grey" size="small" />
												Add a Warehouse
											</Link>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											<Link to={`/companies/brand-create`}>
												<Icon name="beer" color="grey" />
												<Icon name="plus" color="grey" size="small" />
												Add a Brand
											</Link>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											<Link to={`/companies/client-create`}>
												<Icon name="address book" color="grey" />
												<Icon name="plus" color="grey" size="small" />
												Add a Client
											</Link>
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						</Grid.Column>
					)}
					<Grid.Column>
						<h3>Quick Actions</h3>
						<Table basic="very">
							<Table.Body>
								<Table.Row>
									<Table.Cell>
										<Link to="/keg-fill">
											<Icon name="tint" color="grey" />
											Fill Kegs
										</Link>
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell>
										<Link to="/keg-batch-update">
											<Icon name="user circle" color="grey" />
											Batch Update Keg Information
										</Link>
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell>
										<Link to="/dispatch">
											<Icon name="truck" color="grey" />
											Dispatch
										</Link>{' '}
										or{' '}
										<Link to="/receive">
											<Icon name="history" color="grey" />
											Receive
										</Link>{' '}
										Kegs
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};
export default DashboardWelcomeWidget;
