import { getObjFromLink } from '../helpers';

export const formatLocationData = (location, companyLink, isNew) => {
	const currentTime = new Date();
	let locationFields = {};

	if (isNew) {
		locationFields = {
			...location,
			isArchive: false,
			dateCreated: currentTime
		};
	} else {
		locationFields = {
			...location,
			dateUpdated: currentTime
		};
	}
	locationFields.companyLink = companyLink;
	locationFields.companyObj = getObjFromLink(companyLink);

	return {
		...locationFields
	};
};
