import React from 'react';
import { Label } from 'semantic-ui-react';

const TypeLabel = ({ type, className, horizontal }) => {
	const renderLabel = () => {
		switch (type) {
			case 'keg':
				return (
					<Label color="blue" horizontal={horizontal} className={className}>
						Keg
					</Label>
				);
			case 'fridge':
				return (
					<Label color="teal" horizontal={horizontal} className={className}>
						Fridge
					</Label>
				);
			case 'gas':
				return (
					<Label color="olive" horizontal={horizontal} className={className}>
						Gas Bottle
					</Label>
				);
			case 'bucket':
				return (
					<Label color="violet" horizontal={horizontal} className={className}>
						Bucket
					</Label>
				);
			case 'tap':
				return (
					<Label color="purple" horizontal={horizontal} className={className}>
						Tap
					</Label>
				);
			default:
				return (
					<Label color="grey" horizontal={horizontal} className={className}>
						Not Set
					</Label>
				);
		}
	};
	return renderLabel();
};

export default TypeLabel;
