import axios from 'axios';
import { useMemo } from 'react';
import { useAuth, useUser } from '../auth';
import { useClients } from '../clients';
import { useCompanies } from '../companies';
import { useCompany } from '../company';
import { getObjFromLink } from '../helpers';
import { usePartners } from '../partners';

export function useKegActions() {
	const { claims } = useAuth();
	const { clients } = useClients();
	const { companies } = useCompanies();
	const { partners } = usePartners();

	const { company } = useCompany();
	const user = useUser();
	const sendEmailNotication = useMemo(() => {
		return company?.sendEmailNotificationToClients
			? company?.sendEmailNotificationToClients
			: false;
	}, [company]);
	const adminNotificationObj = useMemo(() => {
		return company?.sendEmailNotificationToAdmin
			? {
					sendEmailNotificationToAdmin: company.sendEmailNotificationToAdmin,
					adminEmail: company.adminNotificationEmailAddress
			  }
			: { sendEmailNotificationToAdmin: false };
	}, [company]);
	const dispatchKegs = async ({ client, warehouse, coords }, kegs) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const fullClientDetails =
			clients.find((item) => item.id === client.id) ||
			companies.find((company) => company.id === client.id);
		const fullPartnerDetails = companies.find(
			(company) => company.id === client.id
		);

		// console.log(fullClientDetails, 'ff');
		// console.log(fullPartnerDetails, 'partner');

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/dispatch`,
			{
				client,
				warehouse,
				kegs,
				user,
				companyLink: claims.companyLink,
				coords,
				platform: 'web',
				fullClientDetails,
				fullPartnerDetails,
				company,
				sendEmailNotication,
				adminNotificationObj
			},
			config
		);

		const batch = data;

		return batch;
	};

	const receiveKegs = async (
		{ warehouse, status, issueReason, coords },
		kegs
	) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/kegsReceive`,
			{
				warehouse,
				status,
				issueReason,
				kegs,
				user,
				companyLink: claims.companyLink,
				platform: 'web',
				coords
			},
			config
		);

		const batch = data;

		return batch;
	};

	const emptyKeg = async (kegToEmpty) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/empty`,
			{ platform: 'web', user, kegToEmpty },
			config
		);

		const batch = data;

		return batch;
	};

	const onboardKegs = async (kegsToOnboard, values) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/onBoard`,
			{
				user,
				kegsToOnboard,
				companyLink: claims.companyLink,
				values,
				platform: 'web'
			},
			config
		);

		const batch = data;

		return batch;
	};

	const fillKegs = async ({ warehouse, brand, batch, partnerLink }, kegs) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/fill`,
			{
				warehouse,
				brand,
				batch,
				kegs,
				user,
				companyLink: claims.companyLink,
				platform: 'web'
			},
			config
		);

		const dbBatch = data;

		return dbBatch;
	};

	const createTempTags = async ( kegs ) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/createTempTags`,
			{
				kegs,
				user
			},
			config
		);

		const dbBatch = data;

		return dbBatch;
	};

	const transferKegs = async ({ fromWarehouse, toWarehouse }, kegs) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/transfer`,
			{
				fromWarehouse,
				toWarehouse,
				kegs,
				user,
				companyLink: claims.companyLink,
				platform: 'web'
			},
			config
		);

		const batch = data;

		return batch;
	};

	const updateKeg = async (keg, values) => {
		return await updateKegs([keg], values);
	};

	const updateKegs = async (kegs, values) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/bulkUpdate`,
			{ kegs, values, claims, user, platform: 'web' },
			config
		);

		const batch = data;

		return batch;
	};

	const archiveIssueKeg = async (keg) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/archiveIssueKeg`,
			{ keg, user, platform: 'web' },
			config
		);

		const batch = data;

		return batch;
	};

	const markKegIssue = async (keg, values) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/markIssueKeg`,
			{ keg, values, user, platform: 'web' },
			config
		);

		const batch = data;

		return batch;
	};

	const markKegFix = async (keg, values) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(
			`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/markFixKeg`,
			{ keg, values, user, platform: 'web' },
			config
		);

		const batch = data;

		return batch;
	};

	return {
		dispatchKegs,
		receiveKegs,
		emptyKeg,
		onboardKegs,
		fillKegs,
		createTempTags,
		transferKegs,
		updateKeg,
		updateKegs,
		archiveIssueKeg,
		markKegIssue,
		markKegFix
	};
}
