import { createReducer } from '../../app/common/util/reducerUtil';
const initialState = {};

export const setRoleAndCompany = (state, payload) => {
	const compSep = payload.companyLink.indexOf('__');
	const compId = payload.companyLink.substring(0, compSep);
	const compName = payload.companyLink.substring(compSep + 2);
	return { ...state, ...payload, companyObj: { name: compName, id: compId } };
};

export const changeCompany = (state, payload) => {
	const compSep = payload.indexOf('__');
	const compId = payload.substring(0, compSep);
	const compName = payload.substring(compSep + 2);
	return {
		...state,
		companyLink: payload,
		companyObj: { name: compName, id: compId },
		isClient: true
	};
};

export default createReducer(initialState, {
	SET_ROLE_AND_COMPANY: setRoleAndCompany,
	CHANGE_COMPANY: changeCompany
});
