import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../app/layout/ui/header';
import { useCompany } from '../../app/context/company';
import { useKegs } from '../../app/context/kegs';
import Map from './Map';
import { useMap } from '../../app/context/googleMap';
const libraries = ['places'];
const Index = () => {
	const { isMapLoaded: isLoaded } = useMap();
	// const { isLoaded } = useLoadScript({
	// 	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	// 	libraries: libraries
	// });

	if (!isLoaded) return <div>Loading...</div>;
	return (
		<div className="page-wrapper">
			<Header title="Map" />
			<Map />
		</div>
	);
};

export default Index;
