import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

export default function LocationStatusLabel({ locationStatus, icon }) {
	switch (locationStatus) {
		case 'in_warehouse__own':
			return (
				<Label color="olive">
					{icon && <Icon name="warehouse" />} Own Warehouse
				</Label>
			);
		case 'in_warehouse__partner':
			return (
				<Label color="olive">
					{icon && <Icon name="warehouse" />} Partner Warehouse
				</Label>
			);
		case 'dispatched__partner':
			return (
				<Label color="teal">
					{icon && <Icon name="truck" />} Dispatched Partner
				</Label>
			);
		case 'dispatched__client':
			return (
				<Label color="teal">
					{icon && <Icon name="truck" />} Dispatched Client
				</Label>
			);
		case 'receive__partner':
			return (
				<Label color="teal">
					{icon && <Icon name="truck" />} To receive from partner
				</Label>
			);
		case 'in_warehouse__issue':
			return (
				<Label color="red">{icon && <Icon name="dolly flatbed" />} Issue</Label>
			);

		default:
			return (
				<Label color="grey">
					{icon && <Icon name="question circle" />} Unknown
				</Label>
			);
	}
}
