import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback
} from 'react';
import {
	getObjFromLink,
	transformDocSnap,
	transformCollectionSnap
} from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';

const FillsContext = createContext({});

export const FillsProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const [monthlyFills, setMonthlyFills] = useState();
	const [totalFills, setTotalFills] = useState();

	useEffect(() => {
		return claims?.companyLink
			? firebase.db
					.collection('reports_fill_count')
					.doc(`${getObjFromLink(claims.companyLink).id}`)
					.collection('monthlyFills')
					.onSnapshot(
						(snap) => setMonthlyFills(transformCollectionSnap(snap)),
						(err) => console.log(err)
					)
			: () => console.log('no compId');
	}, [claims, firebase]);

	useEffect(() => {
		return claims?.companyLink
			? firebase.db
					.collection('reports_fill_count')
					.doc(`${getObjFromLink(claims.companyLink).id}`)
					.onSnapshot(
						(snap) => setTotalFills(transformDocSnap(snap)),
						(err) => console.log(err)
					)
			: () => console.log('no compId');
	}, [claims, firebase]);

	const getKegMonthlyFills = useCallback(
		(kegId, setFills) => {
			const month = new Date().getMonth();
			const year = new Date().getFullYear();
			return firebase.db
				.collection('kegs')
				.doc(kegId)
				.collection('monthlyFills')
				.doc(`${year}-${month}`)
				.onSnapshot(
					(snap) => setFills(transformDocSnap(snap)),
					(err) => console.log(err)
				);
		},
		[firebase]
	);

	return (
		<FillsContext.Provider
			value={{ monthlyFills, totalFills, getKegMonthlyFills }}
		>
			{children}
		</FillsContext.Provider>
	);
};

export const FillsConsumer = FillsContext.Consumer;

export const useFills = () => useContext(FillsContext);
