import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

const StatusLabel = ({ status, className, horizontal }) => {
	const renderLabel = () => {
		switch (status) {
			case 'warehouse':
				return (
					<Label color="olive" horizontal={horizontal} className={className}>
						<Icon name="warehouse" />
						WAREHOUSE
					</Label>
				);
			case 'dispatched':
				return (
					<Label color="teal" horizontal={horizontal} className={className}>
						<Icon name="truck" />
						DISPATCHED
					</Label>
				);
			case 'sent back':
				return (
					<Label color="teal" horizontal={horizontal} className={className}>
						<Icon name="truck" />
						SENT BACK
					</Label>
				);
			case 'new':
				return (
					<Label color="yellow" horizontal={horizontal} className={className}>
						<Icon name="check circle" />
						NEW
					</Label>
				);
			case 'empty':
				return (
					<Label color="brown" horizontal={horizontal} className={className}>
						<Icon name="bell" />
						EMPTY
					</Label>
				);
			case 'issue':
				return (
					<Label color="red" horizontal={horizontal} className={className}>
						<Icon name="dolly flatbed" />
						ISSUE
					</Label>
				);
			case 'discontinued':
				return (
					<Label color="yellow" horizontal={horizontal} className={className}>
						<Icon name="trash alternate" />
						DISCONTINUED
					</Label>
				);
			case 'open':
				return (
					<Label color="red" horizontal={horizontal} className={className}>
						<Icon name="dolly flatbed" />
						OPEN
					</Label>
				);
			case 'fixed':
				return (
					<Label color="green" horizontal={horizontal} className={className}>
						<Icon name="check" />
						FIXED
					</Label>
				);
			case 'returned':
				return (
					<Label color="green" horizontal={horizontal} className={className}>
						<Icon name="check" />
						RETURNED
					</Label>
				);
			case 'archived':
				return (
					<Label color="grey" horizontal={horizontal} className={className}>
						<Icon name="trash alternate" />
						ARCHIVED
					</Label>
				);
			default:
				return (
					<Label color="grey" horizontal={horizontal} className={className}>
						<Icon name="question circle" />
						UNKNOWN
					</Label>
				);
		}
	};
	return renderLabel();
};

export default StatusLabel;
