import React, {
	useEffect,
	useMemo,
	useState,
	createContext,
	useContext
} from 'react';
import { useReports } from '../reports';
import isThisWeek from 'date-fns/is_this_week';
import isThisMonth from 'date-fns/is_this_month';
import isThisSecond from 'date-fns/is_this_second';
import isThisYear from 'date-fns/is_this_year';
import { getObjFromLink } from '../helpers';
import { isThisQuarter } from 'date-fns';
import {
	TotalKegsHelper,
	clientHelper,
	totalVolumeHelper,
	brandHelper
} from '../../../app/helpers/volumeHelpers';
import { useAuth } from '../auth';

const VolumeContext = createContext(null);

const VolumeByBrandProvider = ({ children }) => {
	const { reports_kegs_dispatched, getReport } = useReports();
	const { claims } = useAuth();
	useEffect(() => {
		return claims?.companyLink
			? getReport('reports_kegs_dispatched', 'companyLink')
			: () => console.log('noLink');
	}, [claims, getReport]);
	const [pdfData, setPdfData] = useState(null);
	const [client, setClient] = useState();
	const [filterdKegs, setFilterdKegs] = useState([]);
	const [brand, setBrand] = useState();
	const [totalVolume, setTotalVolume] = useState(0);
	const [totalKegs, setTotalKegs] = useState(0);
	const [date, setDate] = useState();

	useEffect(() => {
		if (reports_kegs_dispatched) {
			const clientKegs = reports_kegs_dispatched.filter(
				(keg) => keg.clientLink
			);
			if (date) {
				if (date === 'weekly') {
					const updatedKegs = clientKegs.filter((keg) =>
						isThisWeek(keg.dateDispatched?.toDate())
					);
					if (brand) {
						brandHelper(
							client,
							brand,
							updatedKegs,
							setFilterdKegs,
							setTotalVolume,
							setTotalKegs
						);
					} else {
						const filterdTotalVolume = totalVolumeHelper(updatedKegs);
						const filterdTotalKegs = TotalKegsHelper(updatedKegs);
						setTotalVolume(filterdTotalVolume);
						setTotalKegs(filterdTotalKegs);
						setBrand(undefined);
						setFilterdKegs(updatedKegs);
					}
				}
				if (date === 'monthly') {
					const updatedKegs = clientKegs.filter((keg) =>
						isThisMonth(keg.dateDispatched?.toDate())
					);
					if (brand) {
						brandHelper(
							client,
							brand,
							updatedKegs,
							setFilterdKegs,
							setTotalVolume,
							setTotalKegs
						);
					} else {
						const filterdTotalVolume = totalVolumeHelper(updatedKegs);
						const filterdTotalKegs = TotalKegsHelper(updatedKegs);
						setTotalVolume(filterdTotalVolume);
						setTotalKegs(filterdTotalKegs);
						setBrand(undefined);
						setFilterdKegs(updatedKegs);
					}
				}
				if (date === 'quartely') {
					const updatedKegs = clientKegs.filter((keg) =>
						isThisQuarter(keg.dateDispatched?.toDate())
					);
					if (brand) {
						brandHelper(
							client,
							brand,
							updatedKegs,
							setFilterdKegs,
							setTotalVolume,
							setTotalKegs
						);
					} else {
						const filterdTotalVolume = totalVolumeHelper(updatedKegs);
						const filterdTotalKegs = TotalKegsHelper(updatedKegs);
						setTotalVolume(filterdTotalVolume);
						setTotalKegs(filterdTotalKegs);
						setBrand(undefined);
						setFilterdKegs(updatedKegs);
					}
				}
				if (date === 'second') {
					const updatedKegs = clientKegs.filter((keg) =>
						isThisSecond(keg.dateDispatched?.toDate())
					);
					if (brand) {
						brandHelper(
							client,
							brand,
							updatedKegs,
							setFilterdKegs,
							setTotalVolume,
							setTotalKegs
						);
					} else {
						const filterdTotalVolume = totalVolumeHelper(updatedKegs);
						const filterdTotalKegs = TotalKegsHelper(updatedKegs);
						setTotalVolume(filterdTotalVolume);
						setTotalKegs(filterdTotalKegs);
						setBrand(undefined);
						setFilterdKegs(updatedKegs);
					}
				}
				if (date === 'year') {
					const updatedKegs = clientKegs.filter((keg) =>
						isThisYear(keg.dateDispatched?.toDate())
					);
					if (brand) {
						brandHelper(
							client,
							brand,
							updatedKegs,
							setFilterdKegs,
							setTotalVolume,
							setTotalKegs
						);
					} else {
						const filterdTotalVolume = totalVolumeHelper(updatedKegs);
						const filterdTotalKegs = TotalKegsHelper(updatedKegs);
						setTotalVolume(filterdTotalVolume);
						setTotalKegs(filterdTotalKegs);
						setBrand(undefined);
						setFilterdKegs(updatedKegs);
					}
				}
			} else {
				if (brand) {
					brandHelper(
						client,
						brand,
						clientKegs,
						setFilterdKegs,
						setTotalVolume,
						setTotalKegs
					);
				} else {
					setFilterdKegs(clientKegs);
					const filterdTotalVolume = totalVolumeHelper(clientKegs);
					const filterdTotalKegs = TotalKegsHelper(clientKegs);
					setTotalVolume(filterdTotalVolume);
					setBrand(undefined);
					setTotalKegs(filterdTotalKegs);
				}
			}
		}
	}, [reports_kegs_dispatched, date, client, brand]);

	useEffect(() => {
		if (!brand) {
			setClient(undefined);
		}
	}, [brand]);

	return (
		<VolumeContext.Provider
			value={{
				pdfData,
				setPdfData,
				dispatchedVolume: filterdKegs,
				totalVolume,
				totalKegs,
				setBrand,
				setClient,
				brand,
				client,
				setDate
			}}
		>
			{children}
		</VolumeContext.Provider>
	);
};

export default VolumeByBrandProvider;
export const useVolumeByBrand = () => useContext(VolumeContext);
