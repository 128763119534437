import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo,
	useCallback
} from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { useIntegrations } from '.';
import axios from 'axios';
import qs from 'qs';
import { toastr } from 'react-redux-toastr';
import axiosRetry from 'axios-retry';

const ServiceM8Context = createContext({});

export const ServiceM8Provider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();
	const { integrations } = useIntegrations();
	const serviceM8 = useMemo(
		() => integrations?.find((i) => i.id === 'servicem8'),
		[integrations]
	);

	const getJobCards = useCallback(() => {
		axiosRetry(axios, { retries: 3 });

		if (claims?.companyLink) {
			return axios
				.get(
					`${
						process.env.REACT_APP_CLOUD_FUNCTIONS
					}/app/serviceM8/jobCards?compID=${
						getObjFromLink(claims?.companyLink).id
					}`
				) // The first request fails and the second returns 'ok'
				.then(
					(result) => result // 'ok'
				);
		}
	}, [claims]);

	const getClients = useCallback(() => {
		if (claims?.companyLink) {
			var config = {
				method: 'get',
				url: `${
					process.env.REACT_APP_CLOUD_FUNCTIONS
				}/app/serviceM8/clients?compID=${
					getObjFromLink(claims?.companyLink).id
				}`
			};

			return axios(config);
		}
	}, [claims]);

	const addToJobCard = async (job, kegs) => {
		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/serviceM8/add_to_job_card`,
			data: { job, kegs, compID: getObjFromLink(claims?.companyLink).id }
		};

		return axios(config).then((d) => {
			toastr.success('Success', 'Dispatch sent to ServiceM8');
		});
	};

	const createJobCard = async (job, kegs) => {
		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/serviceM8/create_job_card`,
			data: { job, kegs, compID: getObjFromLink(claims?.companyLink).id }
		};

		return axios(config).then((d) => {
			toastr.success('Success', 'Dispatch sent to ServiceM8');
		});
	};

	return (
		<ServiceM8Context.Provider
			value={{ getJobCards, getClients, addToJobCard, createJobCard }}
		>
			{children}
		</ServiceM8Context.Provider>
	);
};

export const ServiceM8Consumer = ServiceM8Context.Consumer;

export const useServiceM8 = () => useContext(ServiceM8Context);
