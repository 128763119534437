import React, { Component } from 'react';
import { Modal, Image, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import './WelcomeModal.css';
import { markAsSeen } from './welcomeActions';
import { withRouter } from 'react-router-dom';

const mapState = (state, ownProps) => ({
	auth: state.firebase.auth,
	profile: state.firebase.profile
});

const actions = {
	markAsSeen
};

class WelcomeModal extends Component {
	state = { open: true };

	close = () => {
		const { auth, markAsSeen } = this.props;
		this.setState({ open: false });
		markAsSeen(auth.uid);
	};

	start = () => {
		const { auth, profile, markAsSeen, history } = this.props;
		this.setState({ open: false });
		markAsSeen(auth.uid);
		history.push(`/companies/locations`);
	};

	render() {
		const settings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		const { open } = this.state;
		const { profile } = this.props;
		return (
			<Modal
				size="small"
				dimmer="blurring"
				open={open}
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={this.close}
				className="welcome-modal"
			>
				<Modal.Content image>
					<Modal.Description>
						<Slider {...settings}>
							<div className="slider-inner-content">
								<h3>Welcome to Keg Tracker {profile.nameFirst}</h3>
								<p>
									Keg Tracker is a product of Beverage Insights and is designed
									to help you gain better insights around your kegs.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Company Information</h3>
								<p>
									Be sure to capture all your company information, including
									Brands you stock, Locations (Warehouses) you operate from, and
									any Clients you interact with (Companies receiving your kegs),
									you’ll need this later to use the system.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>See all your Kegs in one place</h3>
								<p>
									You can go to Kegs → List of kegs to see all the kegs you have
									in the system, and what their various statuses and whereabouts
									are.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Bulk Update Information</h3>
								<p>
									You can perform a bulk update on your kegs by going to Kegs →
									Bulk update kegs to change certain fields across all selected
									kegs.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Fill your Kegs</h3>
								<p>
									You’re ready to start tracking your kegs by filling them with
									the brands you created in your profile, so whenever you fill
									kegs, be sure to capture that on the system under Kegs → Fill
									Kegs.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Dispatch Kegs</h3>
								<p>
									Dispatch kegs to clients by going to Stock Management →
									Dispatch. This will release the stock from your location and
									allocate it to that client.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Receive Kegs</h3>
								<p>
									When kegs are returned to the location, scan them back into
									the system by going to Stock Management → Receive. We’ll know
									that the keg is back at the warehouse and empty - ready to be
									filled again.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Reporting</h3>
								<p>
									To track your kegs, head to the reporting section to start
									tracking everything happening with your kegs throughout the
									system.
								</p>
							</div>
							<div className="slider-inner-content">
								<h3>Get Started</h3>
								<p>
									Let’s get started by capturing your company information, click
									on the yellow button below to start adding your locations.
								</p>
							</div>
						</Slider>
					</Modal.Description>
					<Image
						wrapped
						size="medium"
						src="/assets/illustrations/welcome-illustration.svg"
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button
						color="yellow"
						content="LOAD MY COMPANY DETAILS"
						onClick={this.start}
						floated="left"
					/>
					<Button content="SKIP INTRO" onClick={this.close} />
				</Modal.Actions>
			</Modal>
		);
	}
}

export default withRouter(connect(mapState, actions)(WelcomeModal));
