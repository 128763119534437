import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from '../../app/layout/ui/header';
import { Grid } from 'semantic-ui-react';
import WelcomeModal from '../modals/WelcomeModal';
import DashboardWelcomeWidget from './DashboardWelcomeWidget';
import DashboardKegStatus from './DashboardKegStatus';
import DashboardOverviewStats from './DashboardOverviewStats';
import DashboardBrandsStats from './DashboardBrandsStats';
import DashboardLocationsStats from './DashboardLocationsStats';
import DashboardPartnersStats from './DashboardPartnersStats';
import DashboardClientsStats from './DashboardClientsStats';
import MostFilledKegLeaderBoard from './MostFilledKegLeaderBoard';
import KegTurnTime from './KegTurnTimes';

const mapState = (state, ownProps) => {
	const authCompLink =
		state.claims && state.claims.companyLink
			? state.claims.companyLink
			: (state.firebase.profile.isLoaded &&
					state.firebase.profile.companyLink) ||
			  localStorage.getItem('authCompLink');
	const authCompId =
		(state.firebase.profile.companyObj &&
			state.firebase.profile.companyObj.id) ||
		localStorage.getItem('authCompId');
	return {
		authCompId,
		authCompLink,
		auth: state.firebase.auth,
		profile: state.firebase.profile
	};
};

class Dashboard extends Component {
	render() {
		const { authCompId } = this.props;
		const { showWelcome, role } = this.props.profile;

		return (
			<div className="page-wrapper">
				{showWelcome === true ? <WelcomeModal /> : null}
				<Header title="Dashboard" />
				<DashboardKegStatus />
				<div className="page-padding dashboard">
					<Grid>
						<Grid.Row columns={2}>
							<Grid.Column>
								<KegTurnTime />
								<MostFilledKegLeaderBoard />
								<DashboardBrandsStats />
								<DashboardOverviewStats />
							</Grid.Column>
							<Grid.Column>
								<DashboardClientsStats />
								<DashboardLocationsStats />
								<DashboardPartnersStats />
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Grid>
						<Grid.Row columns={1}>
							<Grid.Column>
								<DashboardWelcomeWidget role={role} authCompId={authCompId} />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</div>
		);
	}
}

export default compose(connect(mapState))(Dashboard);
