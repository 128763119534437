import { useState, useEffect } from 'react';
import { useFirebase } from '../Firebase';
import { transformCollectionSnap } from '../helpers';
export const useOptions = () => {
	const {
		gettingVolumeOptions,
		gettingTypeOptions,
		gettingStyleOptions,
		gettingCouplerOptions
	} = useFirebase();
	const [typeOptions, setTypeOptions] = useState([]);
	const [couplerOptions, setCouplerOptions] = useState([]);
	const [kegStyleOptions, setStyleOptions] = useState([]);
	const [volumeOptions, setVolumeOptions] = useState([]);

	useEffect(() => {
		gettingVolumeOptions().onSnapshot(
			(snap) => {
				setVolumeOptions(transformCollectionSnap(snap));
				console.log(transformCollectionSnap(snap), 'snapy');
			},
			(err) => console.log(err)
		);
		gettingTypeOptions().onSnapshot(
			(snap) => {
				setTypeOptions(transformCollectionSnap(snap));
				console.log(transformCollectionSnap(snap), 'snapy');
			},
			(err) => console.log(err)
		);
		gettingStyleOptions().onSnapshot(
			(snap) => {
				setStyleOptions(transformCollectionSnap(snap));
				console.log(transformCollectionSnap(snap), 'snapy');
			},
			(err) => console.log(err)
		);
		gettingCouplerOptions().onSnapshot(
			(snap) => {
				setCouplerOptions(transformCollectionSnap(snap));
				console.log(transformCollectionSnap(snap), 'snapy');
			},
			(err) => console.log(err)
		);
	}, [
		gettingVolumeOptions,
		gettingCouplerOptions,
		gettingStyleOptions,
		gettingTypeOptions
	]);
	return { volumeOptions, typeOptions, kegStyleOptions, couplerOptions };
};

// Redux
export const FETCH_CLIENT_KEGS = 'FETCH_CLIENT_KEGS';

// Form Options
export const typeOptions = [
	{ key: 'keg', text: 'Keg', value: 'keg' },
	{ key: 'fridge', text: 'Fridge', value: 'fridge' },
	{ key: 'gas', text: 'Gas Bottle', value: 'gas' },
	{ key: 'bucket', text: 'Bucket', value: 'bucket' },
	{ key: 'tap', text: 'Tap', value: 'tap' }
];

export const couplerOptions = [
	{ key: 'a', text: 'A', value: 'a' },
	{ key: 'd', text: 'D', value: 'd' },
	{ key: 'g', text: 'G', value: 'g' },
	{ key: 'm', text: 'M', value: 'm' },
	{ key: 's', text: 'S', value: 's' },
	{ key: 'u', text: 'U', value: 'u' }
];

export const kegStyleOptions = [
	{ key: 'Mini lady', text: 'Mini lady', value: 'Mini lady' },
	{ key: '1 TDU', text: '1 TDU', value: '1 TDU' },
	{ key: '2 TDU', text: '2 TDU', value: '2 TDU' },
	{ key: '5ℓ Growler', text: '5ℓ Growler', value: '5ℓ Growler' },
	{ key: '9ℓ Growler', text: '9ℓ Growler', value: '9ℓ Growler' },
	{ key: '10ℓ', text: '10ℓ', value: '10ℓ' },
	{ key: '18ℓ Cornelius', text: '18ℓ Cornelius', value: '18ℓ Cornelius' },
	{ key: '19ℓ Cornelius', text: '19ℓ Cornelius', value: '19ℓ Cornelius' },
	{ key: '20ℓ Slimline', text: '20ℓ Slimline', value: '20ℓ Slimline' },
	{ key: '20ℓ PET', text: '20ℓ PET', value: '20ℓ PET' },
	{ key: '20ℓ Euro', text: '20ℓ Euro', value: '20ℓ Euro' },
	{ key: '20ℓ Bucket', text: '20ℓ Bucket', value: '20ℓ Bucket' },
	{ key: '25ℓ Sankey', text: '25ℓ Sankey', value: '25ℓ Sankey' },
	{ key: '30ℓ Slimline', text: '30ℓ Slimline', value: '30ℓ Slimline' },
	{ key: '30ℓ Super Slim', text: '30ℓ Super Slim', value: '30ℓ Super Slim' },
	{ key: '30ℓ Euro', text: '30ℓ Euro', value: '30ℓ Euro' },
	{ key: '40ℓ', text: '40ℓ', value: '40ℓ' },
	{ key: '50ℓ Euro', text: '50ℓ Euro', value: '50ℓ Euro' },
	{ key: '50ℓ', text: '50ℓ', value: '50ℓ' }
];

export const volumeOptions = [
	{ key: '2ℓ', text: '2ℓ', value: '2' },
	{ key: '5ℓ', text: '5ℓ', value: '5' },
	{ key: '9ℓ', text: '9ℓ', value: '9' },
	{ key: '10ℓ', text: '10ℓ', value: '10' },
	{ key: '18ℓ', text: '18ℓ', value: '18' },
	{ key: '19ℓ', text: '19ℓ', value: '19' },
	{ key: '20ℓ', text: '20ℓ', value: '20' },
	{ key: '25ℓ', text: '25ℓ', value: '25' },
	{ key: '30ℓ', text: '30ℓ', value: '30' },
	{ key: '40ℓ', text: '40ℓ', value: '40' },
	{ key: '50ℓ', text: '50ℓ', value: '50' }
];

// // Form Options
// export const typeOptions = [
// 	{ key: 'keg', text: 'Keg', value: 'keg' },
// 	{ key: 'fridge', text: 'Fridge', value: 'fridge' },
// 	{ key: 'gas', text: 'Gas Bottle', value: 'gas' },
// 	{ key: 'tap', text: 'Tap', value: 'tap' }
// ];

// export const couplerOptions = [
// 	{ key: 'a', text: 'A', value: 'a' },
// 	{ key: 'd', text: 'D', value: 'd' },
// 	{ key: 'g', text: 'G', value: 'g' },
// 	{ key: 'm', text: 'M', value: 'm' },
// 	{ key: 's', text: 'S', value: 's' },
// 	{ key: 'u', text: 'U', value: 'u' }
// ];

// export const kegStyleOptions = [
// 	{ key: '5ℓ Growler', text: '5ℓ Growler', value: '5ℓ Growler' },
// 	{ key: 'Mini lady', text: 'Mini lady', value: 'Mini lady' },
// 	{ key: '1 TDU', text: '1 TDU', value: '1 TDU' },
// 	{ key: '2 TDU', text: '2 TDU', value: '2 TDU' },
// 	{ key: '9ℓ Growler', text: '9ℓ Growler', value: '9ℓ Growler' },
// 	{ key: '10ℓ', text: '10ℓ', value: '10ℓ' },
// 	{ key: '18ℓ Cornelius', text: '18ℓ Cornelius', value: '18ℓ Cornelius' },
// 	{ key: '19ℓ Cornelius', text: '19ℓ Cornelius', value: '19ℓ Cornelius' },
// 	{ key: '20ℓ Slimline', text: '20ℓ Slimline', value: '20ℓ Slimline' },
// 	{ key: '20ℓ PET', text: '20ℓ PET', value: '20ℓ PET' },
// 	{ key: '20ℓ Euro', text: '20ℓ Euro', value: '20ℓ Euro' },
// 	{ key: '25ℓ Sankey', text: '25ℓ Sankey', value: '25ℓ Sankey' },
// 	{ key: '30ℓ Slimline', text: '30ℓ Slimline', value: '30ℓ Slimline' },
// 	{ key: '30ℓ Euro', text: '30ℓ Euro', value: '30ℓ Euro' },
// 	{ key: '50ℓ Euro', text: '50ℓ Euro', value: '50ℓ Euro' }
// ];

// export const volumeOptions = [
// 	{ key: '2', text: '2', value: '2' },
// 	{ key: '5', text: '5', value: '5' },
// 	{ key: '9', text: '9', value: '9' },
// 	{ key: '10', text: '10', value: '10' },
// 	{ key: '18', text: '18', value: '18' },
// 	{ key: '19', text: '19', value: '19' },
// 	{ key: '20', text: '20', value: '20' },
// 	{ key: '25', text: '25', value: '25' },
// 	{ key: '30', text: '30', value: '30' },
// 	{ key: '50', text: '50', value: '50' }
// ];
