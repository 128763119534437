import { getObjFromLink } from '../helpers';

export const formatBatchData = (batch, companyLink, isNew) => {
	const currentTime = new Date();
	let batchFields = {};

	if (isNew) {
		batchFields = {
			...batch,
			dateCreated: currentTime
		};
	} else {
		batchFields = {
			...batch,
			dateUpdated: currentTime
		};
	}

	batchFields.companyLink = companyLink;
	batchFields.companyObj = getObjFromLink(companyLink);
	if (batch.bestBefore?._d) {
		batchFields.bestBefore = batch.bestBefore._d;
	}

	// Format Brand into an Object to store
	if (batch.brandLink) {
		const sep = batch.brandLink.indexOf('__');
		const brandId = batch.brandLink.substring(0, sep);
		const brandName = batch.brandLink.substring(sep + 2);
		batchFields.brandObj = {
			name: brandName,
			id: brandId
		};
	}

	return {
		...batchFields
	};
};
