import React, { useState, useEffect, useMemo } from 'react';
import DashboardKegStatusCard from './DashboardKegStatusCard';
import { useKegs } from '../../../app/context/kegs';
import LoadingComponent from '../../../app/layout/LoadingComponent';

export default function DashboardLocationsStats({ title }) {
	const { statusStats } = useKegs();

	return (
		<div className="dashboard-keg-status-drawer">
			<DashboardKegStatusCard
				title="New"
				className="new"
				to="kegs-list/new"
				description="Stock that has not been onboarded."
				value={statusStats?.new}
			/>
			<DashboardKegStatusCard
				title="Empty"
				className="empty"
				to="kegs-list/empty"
				description="Empty stock, ready to be filled."
				value={statusStats?.empty}
			/>
			<DashboardKegStatusCard
				title="Warehouse"
				className="warehouse"
				to="kegs-list/warehouse"
				description="Stock in the warehouse."
				value={statusStats?.filled}
			/>
			<DashboardKegStatusCard
				title="Dispatched"
				className="dispatched"
				to="kegs-list/dispatched"
				description="Stock dispatched to clients / partners."
				value={statusStats?.dispatched}
			/>
			<DashboardKegStatusCard
				title="Issue Kegs"
				className="issue"
				to="kegs-list/issue"
				description="Stock that currently has an issue"
				value={statusStats?.issue}
			/>
		</div>
	);
}
