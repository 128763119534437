import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';

const ActivityContext = createContext({});

export const ActivityProvider = ({ children }) => {
	const firebase = useFirebase();

	const getActivity = (setActivity, collection, doc) => {
		return firebase.activity(collection, doc).onSnapshot(
			(snap) => setActivity(transformCollectionSnap(snap)),
			(err) => console.log(err)
		);
	};

	return (
		<ActivityContext.Provider value={{ getActivity }}>
			{children}
		</ActivityContext.Provider>
	);
};

export const ActivityConsumer = ActivityContext.Consumer;

export const useActivity = () => useContext(ActivityContext);
