import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import config from './config';
import { updateKeg } from '../../../features/kegs/kegActions';

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const firestore = firebase.firestore;

const updateKegg = (keg) => {
	const kegRef = db.collection('kegs').doc(keg);
	const batch = db.batch();

	batch.update(kegRef, {
		companyLink: 'JzO3YpHaFo2OlwZospwU__Kweza Distributors Ltd',
		isFilled: false,
		isNew: true,
		kegStatus: 'new',
		isArchive: false
	});
	batch.update(kegRef, {
		dateDispatched: firebase.firestore.FieldValue.delete(),
		dateEmptied: firebase.firestore.FieldValue.delete(),
		dateFilled: firebase.firestore.FieldValue.delete(),
		dataMigrated: firebase.firestore.FieldValue.delete(),
		dateOnboarded: firebase.firestore.FieldValue.delete(),
		dateReceived: firebase.firestore.FieldValue.delete(),
		dateTransferred: firebase.firestore.FieldValue.delete(),
		dispatchedBy: firebase.firestore.FieldValue.delete(),
		emptiedBy: firebase.firestore.FieldValue.delete(),
		fillCount: firebase.firestore.FieldValue.delete(),
		filledBy: firebase.firestore.FieldValue.delete(),
		gps: firebase.firestore.FieldValue.delete(),
		interactions: firebase.firestore.FieldValue.delete(),
		lastFillBrandLink: firebase.firestore.FieldValue.delete(),
		lastFillBrandObj: firebase.firestore.FieldValue.delete(),
		locationLink: firebase.firestore.FieldValue.delete(),
		locationObj: firebase.firestore.FieldValue.delete(),
		locationStatus: firebase.firestore.FieldValue.delete(),
		onbordedBy: firebase.firestore.FieldValue.delete(),
		partnerGps: firebase.firestore.FieldValue.delete(),
		partnerLink: firebase.firestore.FieldValue.delete(),
		partnerObj: firebase.firestore.FieldValue.delete(),
		receivedBy: firebase.firestore.FieldValue.delete(),
		responsibleParty: firebase.firestore.FieldValue.delete(),
		style: firebase.firestore.FieldValue.delete(),
		transferedBy: firebase.firestore.FieldValue.delete(),
		type: firebase.firestore.FieldValue.delete(),
		workedOnBy: firebase.firestore.FieldValue.delete()
	});

	return batch
		.commit()
		.then(() => {
			console.log('Document successfully updated!');
		})
		.catch((error) => {
			console.error('Error updating document: ', error);
		});
};

const Firebase = {
	/////////////////////// AUTH ///////////////////////////////
	auth,

	login: ({ email, password }) => {
		return auth.signInWithEmailAndPassword(email, password);
	},
	signOut: () => {
		return auth.signOut();
	},
	checkUserAuth: (user) => {
		return auth.onAuthStateChanged(user);
	},

	claims: async () => {
		return await auth.currentUser.getIdTokenResult().then((idTokenResult) => {
			if (
				idTokenResult.claims.companyLink ===
				'XbirPzxY9JciN6Vn1TZd__LâAtelier Distillery'
			) {
				return {
					...idTokenResult.claims,
					companyLink: 'XbirPzxY9JciN6Vn1TZd__L’Atelier Distillery'
				};
			}
			return {
				...idTokenResult.claims
			};
		});
	},
	updatePassword: (password) => {
		return auth.currentUser.updatePassword(password);
	},

	sendForgotPasswordEmail: ({ email }) => {
		return auth.sendPasswordResetEmail(email);
	},

	/////////////////////// FIRESTORE ///////////////////////////////
	db,
	firestore,
	batch: db.batch,
	//----------------- USERS -----------------

	createNewUser: (userData) => {
		return db
			.collection('users')
			.doc()
			.set(userData);
	},
	anounceMentViews: () => {
		return db.collection('anouncement');
	},

	createUserWithId: (userData) => {
		return db
			.collection('users')
			.doc(userData.id)
			.set(userData);
	},

	user: (userId) => {
		return db.collection('users').doc(userId);
	},

	users: (userId) => {
		return db.collection('users');
	},

	//----------------- REPORTS -----------------

	reports: (report) => {
		return db.collection(report);
	},

	//------------------BRANDS--------------

	brands: (compId) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('brands');
	},

	//------------------LOCATIONS--------------

	locations: (compId) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('locations');
	},

	//------------------CLIENTS--------------

	clients: (compId) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('clients');
	},

	//----------------KEGS-------------------

	companyKegs: (compLink) => {
		return db.collection('kegs').where('companyLink', '==', compLink);
	},

	distributorKegs: (compLinks) => {
		return db.collection('kegs').where('companyLink', "in", compLinks).where('locationStatus', '==', 'dispatched__client');
	},

	// multipleUpdate: (kegs) => {
	// 	kegs.forEach((keg) => {
	// 		// const kegRef = db.collection('kegs').doc(keg.label);
	// 		updateKegg(keg.label);
	// 	});
	// },

	// getSingleKeg: (keg) => {
	// 	var washingtonRef = db.collection('kegs').doc(keg);

	// 	return washingtonRef
	// 		.update({
	// 			label: 'KT5853'
	// 		})
	// 		.then(() => {
	// 			console.log('Document successfully updated!');
	// 		})
	// 		.catch((error) => {
	// 			// The document probably doesn't exist.
	// 			console.error('Error updating document: ', error);
	// 		});
	// },
	// distributorKegs: (kegId) => {
	// 	return db
	// 		.collection('kegs')
	// 		.where('label', '==', kegId)
	// 		.where('locationStatus', '==', 'dispatched__client');
	// },


	partnerKegs: (compLink) => {
		return db.collection('kegs').where('partnerLink', '==', compLink);
	},

	//------------------COMPANIES--------------

	companies: () => {
		return db.collection('companies');
	},

	//------------------COMPANY--------------

	company: (compId) => {
		return db.collection('companies').doc(compId);
	},

	//------------------PARTNERS--------------

	partners: (compId) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('partners');
	},

	//------------------BATCHES--------------

	batches: (compId) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('batches');
	},

	//------------------ACTIVITY--------------

	activity: (collectionName, docID) => {
		return db
			.collection(collectionName)
			.doc(docID)
			.collection('interactions');
	},

	//------------------INTEGRATIONS--------------

	integrations: (compId) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('integrations');
	},

	//------------------ARCHIVE OLD KEG--------------
	archiveOldKeg: (id) => {
		db.collection('kegs')
			.doc(id)
			.update({
				isArchive: true
			});
	},

	//------------------GETTING VOLUME OPTIONS--------------
	gettingVolumeOptions: () => {
		return db.collection('volumeOptions');
	},

	//------------------GETTING TYPE OPTIONS--------------
	gettingTypeOptions: () => {
		return db.collection('typeOptions');
	},
	//------------------GETTING STYLE OPTIONS--------------
	gettingStyleOptions: () => {
		return db.collection('styleOptions');
	},
	//------------------GETTING COUPLER OPTIONS--------------
	gettingCouplerOptions: () => {
		return db.collection('couplerOptions');
	},

	////// MIGRATE TO NEW KEG
	migrateToNewKeg: (newKeg, newData) => {
		return db
			.collection('kegs')
			.doc(newKeg)
			.set({ ...newData, id: newKeg, label: newKeg });
	}
};

export default Firebase;
