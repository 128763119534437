import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
import {
	transformCollectionSnap,
	getObjFromLink,
	getObjFromLink_WithType
} from '../helpers';
import { useFirebase } from '../Firebase';
import { useKegs } from '../kegs';
import { useAuth } from '../auth';
import { useLocations } from '../locations';
import { useClients } from '../clients';
import { formatBrandData } from './formatting';

const BrandsContext = createContext({});

export const BrandsProvider = ({ children }) => {
	const firebase = useFirebase();

	const { claims } = useAuth();
	const { companyKegs, partnerKegs } = useKegs();
	const { locations } = useLocations();
	const { clients } = useClients();
	const [brands, setBrands] = useState();

	useEffect(() => {
		return claims?.companyLink
			? firebase
					.brands(getObjFromLink(claims.companyLink).id)
					.orderBy('name')
					.onSnapshot(
						(snap) => setBrands(transformCollectionSnap(snap)),
						(err) => console.log(err)
					)
			: () => console.log('no compId');
	}, [claims, firebase]);

	const createBrand = async (brand) => {
		const newBrand = formatBrandData(brand, claims.companyLink, true);
		return await firebase
			.brands(getObjFromLink(claims.companyLink).id)
			.add(newBrand);
	};

	const updateBrand = async (brand, id) => {
		const oldBrand = brands.find((b) => b.id === id);
		const updatedBrand = formatBrandData(brand, claims.companyLink);

		const batch = firebase.db.batch();
		if (oldBrand.name !== brand.name && companyKegs && partnerKegs) {
			const brandLink = `${id}__${brand.name}`;

			const kegs = [...companyKegs, ...partnerKegs];
			const brandedKegs = kegs.filter((k) => k.brandObj?.id === id);

			brandedKegs.forEach(
				async (k) =>
					await batch.update(firebase.db.collection('kegs').doc(k.id), {
						brandLink,
						brandObj: getObjFromLink(brandLink)
					})
			);
		}

		await batch.update(
			firebase.brands(getObjFromLink(claims.companyLink).id).doc(id),
			updatedBrand
		);
		return await batch.commit();
	};

	const archiveBrand = async (isArchive, id) => {
		return await firebase
			.brands(getObjFromLink(claims.companyLink).id)
			.doc(id)
			.update({
				isArchive: !isArchive,
				dateUpdated: new Date()
			});
	};

	const brandStats = useMemo(() => {
		if (brands && companyKegs && partnerKegs) {
			const kegs = companyKegs
				? [
						...companyKegs?.filter((k) => !k.isArchive),

						...partnerKegs?.filter((k) => !k.isArchive)
				  ]
				: [];
			// Kegs with Brands
			const kegsWithBrands = kegs.filter(
				(keg) => keg.brandLink && keg.isFilled
			);
			const kegsWithoutBrands = kegs.filter(
				(keg) => !keg.brandLink && !keg.isFilled
			);
			// Kegs with Brands & Dispatched
			const kegsDispatched = kegsWithBrands.filter(
				(keg) =>
					keg.locationStatus === 'dispatched__client' ||
					keg.locationStatus === 'dispatched__partner' ||
					keg.locationStatus === 'receive__partner'
			);
			// Kegs with Brands & In Warehouse
			const kegsInWarehouseOwn = kegsWithBrands
				.filter((keg) => keg.locationStatus === 'in_warehouse__own')
				.filter((keg) => keg.partnerLink !== claims?.companyLink);
			const kegsInWarehousePartner = kegsWithBrands
				.filter((keg) => keg.locationStatus === 'in_warehouse__partner')
				.filter((keg) => keg.partnerLink !== claims?.companyLink);
			const kegsWithoutDispatched = kegsWithoutBrands.filter(
				(keg) =>
					keg.locationStatus === 'dispatched__client' ||
					keg.locationStatus === 'dispatched__partner'
			);
			// console.log(kegsInWarehouseOwn, 'kegs in warehouse');
			// Kegs with Brands & In Warehouse
			const kegsWithoutInWarehouse = kegsWithoutBrands
				.filter((keg) => keg.locationStatus === 'in_warehouse__own')
				.filter((keg) => keg.partnerLink !== claims?.companyLink);
			const kegsWithoutInWarehousePartner = kegsWithoutBrands
				.filter((keg) => keg.locationStatus === 'in_warehouse__partner')
				.filter((keg) => keg.partnerLink !== claims?.companyLink);
			// BRANDS

			const brandsTotals = brands.map((brand) => {
				const brandData = {
					name: brand.name,
					countWarehouse: kegsInWarehouseOwn.filter(
						(keg) => keg.brandObj.id === brand.id
					).length,
					countWarehousePartner: kegsInWarehousePartner.filter(
						(keg) => keg.brandObj.id === brand.id
					).length,
					kegsWarehouse: kegsInWarehouseOwn.filter(
						(keg) => keg.brandObj.id === brand.id
					),
					kegsWarehousePartner: kegsInWarehousePartner.filter(
						(keg) => keg.brandObj.id === brand.id
					),
					labelWarehouse:
						kegsInWarehouseOwn.filter((keg) => keg.brandObj.id === brand.id)
							.length > 0
							? 'positive'
							: 'zero',
					labelWarehousePartner:
						kegsInWarehousePartner.filter((keg) => keg.brandObj.id === brand.id)
							.length > 0
							? 'positive'
							: 'zero',
					countDispatched: kegsDispatched.filter(
						(keg) => keg.brandObj.id === brand.id
					).length,
					kegsDispatched: kegsDispatched.filter(
						(keg) => keg.brandObj.id === brand.id
					),
					labelDispatched:
						kegsDispatched.filter((keg) => keg.brandObj.id === brand.id)
							.length > 0
							? 'positive'
							: 'zero',
					type: brand.beerType,
					id: brand.id
				};
				return brandData;
			});

			const brandIds = brands.map((b) => b.id);

			const kegsWithPartnerBrand = kegsWithBrands.filter((k) => {
				return !brandIds.includes(k.brandObj.id);
			});

			let partnerBrandIds = [];
			let partnerBrands = [];

			kegsWithPartnerBrand.forEach((k) => {
				if (!partnerBrandIds.includes(k.brandObj.id)) {
					partnerBrandIds.push(k.brandObj.id);
					partnerBrands.push(k.brandObj);
				}
			});

			const partnerBrandsTotals = partnerBrands.map((brand) => {
				const brandData = {
					name: `${brand.name} (partner)`,
					countWarehouse: kegsInWarehouseOwn.filter(
						(keg) => keg.brandObj.id === brand.id
					).length,
					kegsWarehouse: kegsInWarehouseOwn.filter(
						(keg) => keg.brandObj.id === brand.id
					),
					labelWarehouse:
						kegsInWarehouseOwn.filter((keg) => keg.brandObj.id === brand.id)
							.length > 0
							? 'positive'
							: 'zero',
					kegsDispatched: kegsDispatched.filter(
						(keg) => keg.brandObj.id === brand.id
					),
					countWarehousePartner: kegsInWarehousePartner.filter(
						(keg) => keg.brandObj.id === brand.id
					).length,
					kegsWarehousePartner: kegsInWarehousePartner.filter(
						(keg) => keg.brandObj.id === brand.id
					),
					labelWarehousePartner:
						kegsInWarehousePartner.filter((keg) => keg.brandObj.id === brand.id)
							.length > 0
							? 'positive'
							: 'zero',

					labelDispatched:
						kegsDispatched.filter((keg) => keg.brandObj.id === brand.id)
							.length > 0
							? 'positive'
							: 'zero',
					countDispatched: kegsDispatched.filter(
						(keg) => keg.brandObj.id === brand.id
					).length,
					type: brand.beerType ? brand.beerType : '',
					id: brand.id
				};
				return brandData;
			});

			const totals = [...brandsTotals, ...partnerBrandsTotals];

			totals.sort((a, b) => (a.name > b.name ? 1 : -1));

			// STATS OBJECT
			const brandStats = {};
			brandStats.countBrands = brands.length;
			brandStats.countKegsAll = kegs.length;
			brandStats.countKegsWithBrands = kegsWithBrands.length;
			brandStats.countKegsWithBrandsPartner = kegsWithPartnerBrand.length;
			brandStats.countKegsWithoutBrands = kegsWithoutBrands.length;

			brandStats.countKegsWithoutDispatched = kegsWithoutDispatched.length;
			brandStats.countKegsWithoutInWarehouse = kegsWithoutInWarehouse.length;
			brandStats.countKegsWithoutInWarehousePartner =
				kegsWithoutInWarehousePartner.length;
			brandStats.countKegsDispatched = kegsDispatched.length;
			brandStats.countKegsInWarehouse = kegsInWarehouseOwn.length;
			brandStats.countKegsInWarehousePartner = kegsInWarehousePartner.length;
			brandStats.brandsTotals = totals;
			return brandStats;
		}
	}, [brands, companyKegs, partnerKegs, claims]);

	const warehouseBrandStats = useMemo(() => {
		if (locations && companyKegs && partnerKegs) {
			const kegs = [
				...companyKegs
					.filter((k) => !k.isArchive)
					.filter((keg) => !keg.partnerLink),

				...partnerKegs.filter((k) => !k.isArchive)
			];

			const locationBrandStats = locations.map((l) => {
				const kegsInLocation = kegs
					.filter((keg) => keg.brandLink && keg.isFilled)
					.filter(
						(keg) =>
							keg.locationStatus === 'in_warehouse__own' ||
							keg.locationStatus === 'in_warehouse__partner'
					)
					.filter((k) => k.locationLink === `${l.id}__${l.name}`);

				const brandsObj = {};
				kegsInLocation.forEach((k) => {
					// console.log(k?.brandLink);
					if (k.brandLink) {
						const volumes = {
							'2': [],
							'5': [],
							'9': [],
							'10': [],
							'18': [],
							'19': [],
							'20': [],
							'30': [],
							'50': []
						};
						const brandLink = brands
							?.map((b) => b.id)
							?.includes(getObjFromLink(k.brandLink).id)
							? `${k.brandLink}---own`
							: `${k.brandLink}---partner`;
						if (Object.keys(brandsObj)?.includes(brandLink)) {
							brandsObj[brandLink] = {
								count: brandsObj[brandLink].count + 1,
								kegs: [...brandsObj[brandLink].kegs, k.id],
								volumes: {
									...(brandsObj[brandLink].volumes
										? brandsObj[brandLink].volumes
										: []),
									[k.volume]: [
										...(brandsObj[brandLink].volumes[k.volume]
											? brandsObj[brandLink].volumes[k.volume]
											: []),
										k.id
									]
								}
							};
						} else {
							brandsObj[brandLink] = {
								count: 1,
								kegs: [k.id],
								volumes: { ...volumes, [k.volume]: [k.id] }
							};
						}
					}
				});
				const brandsArr = Object.keys(brandsObj).reduce((arr, link) => {
					return [
						...arr,
						{ ...brandsObj[link], ...getObjFromLink_WithType(link) }
					];
				}, []);
				return {
					name: l.name,
					brands: brandsArr
				};
			});
			const sortedStats = locationBrandStats.map((i) => {
				const brands = i.brands.sort((a, b) =>
					a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
				);
				return { ...i, brands };
			});
			console.log(sortedStats, 'brands');
			return sortedStats;
		}
	}, [companyKegs, locations, brands, partnerKegs]);

	const clientBrandStats = useMemo(() => {
		if (clients && brands && companyKegs && partnerKegs) {
			const kegs = [
				...companyKegs
					.filter((k) => !k.isArchive)
					.filter((keg) => !keg.partnerLink),

				...partnerKegs.filter((k) => !k.isArchive)
			];
			// .filter((k) => k.type === 'keg');

			const clientBrandStats = clients.map((c) => {
				const kegsAtClient = kegs
					.filter((keg) => keg.brandLink && keg.isFilled)
					// .filter((keg) => keg.kegStatus === 'dispatched')
					.filter(
						(keg) => keg.locationStatus === 'dispatched__client'
						// keg.locationStatus === 'dispatched__partner'
						//keg.locationStatus === 'receive__partner'
					)
					.filter((k) => k.clientLink === `${c.id}__${c.name}`);

				const brandsObj = {};
				kegsAtClient.forEach((k) => {
					if (k.brandLink) {
						const brandLink = brands
							?.map((b) => b.id)
							?.includes(getObjFromLink(k.brandLink).id)
							? `${k.brandLink}---own`
							: `${k.brandLink}---partner`;
						if (Object.keys(brandsObj)?.includes(brandLink)) {
							brandsObj[brandLink] = {
								count: brandsObj[brandLink].count + 1,
								kegs: [...brandsObj[brandLink].kegs, k.id],
								kegsType: [
									...brandsObj[brandLink].kegsType,
									{
										type: k.type,
										label: k.id
									}
								]
							};
						} else {
							brandsObj[brandLink] = {
								count: 1,
								kegs: [k.id],
								kegsType: [{ type: k.type, label: k.id }]
							};
						}
					}
				});
				const brandsArr = Object.keys(brandsObj).reduce((arr, link) => {
					return [
						...arr,
						{ ...brandsObj[link], ...getObjFromLink_WithType(link) }
					];
				}, []);

				return {
					name: c.name,
					brands: brandsArr
				};
			});
			return clientBrandStats;
		}
	}, [brands, clients, companyKegs, partnerKegs]);

	return (
		<BrandsContext.Provider
			value={{
				brands,
				brandStats,
				warehouseBrandStats,
				clientBrandStats,
				createBrand,
				updateBrand,
				archiveBrand
			}}
		>
			{children}
		</BrandsContext.Provider>
	);
};

export const BrandsConsumer = BrandsContext.Consumer;

export const useBrands = () => useContext(BrandsContext);
