/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../ui/icons/';
import { getObjFromLink } from '../../../context/helpers';

const subMenuClickHandler = (e) => {
	e.preventDefault();
	const expanded = e.target.getAttribute('aria-expanded') === 'true' || false;
	e.target.setAttribute('aria-expanded', !expanded);
};

class NavigationItems extends Component {
	render() {
		const { visible, toggle, claims, profile } = this.props;
		// compId
		let compId = 'not-set';
		if (claims?.companyLink) {
			compId = getObjFromLink(claims.companyLink).id;
		}

		// CSS Classes
		const classes = ['main-nav'];
		if (visible) {
			classes.push('open');
		} else classes.push('closed');

		return (
			<nav className={classes.join(' ')}>
				{process.env.REACT_APP_ENV !== 'production' && (
					<div className="staging-label">{process.env.REACT_APP_ENV}</div>
				)}
				<ul className="navigation-items">
					<NavigationItem
						link="/dashboard"
						exact
						icon={icons.faTachometer}
						toggle={toggle}
					>
						Dashboard
					</NavigationItem>
					<li className="navigation-item">
						<a onClick={subMenuClickHandler} aria-expanded="false">
							<FontAwesomeIcon icon={icons.faDatabase} /> Kegs{' '}
							<FontAwesomeIcon icon={icons.faChevronDown} className="arrow" />
						</a>
						<ul className="sub-menu">
							<NavigationItem
								link="/kegs-list"
								icon={icons.faClipboardList}
								toggle={toggle}
							>
								List of Kegs
							</NavigationItem>
							<NavigationItem
								link="/map"
								icon={icons.faMapMarkedAlt}
								toggle={toggle}
							>
								Map
							</NavigationItem>
							<NavigationItem
								link="/keg-batch-update"
								icon={icons.faClipboardCheck}
								toggle={toggle}
							>
								Bulk Update Kegs
							</NavigationItem>
							<NavigationItem
								link="/keg-quick-view"
								icon={icons.faSearch}
								toggle={toggle}
							>
								QuickView
							</NavigationItem>
							<NavigationItem
								link="/inspection-pile"
								icon={icons.faDollyFlatbed}
								toggle={toggle}
							>
								Inspection Pile
							</NavigationItem>
							<NavigationItem
								link="/onboard-kegs"
								icon={icons.faInventory}
								toggle={toggle}
							>
								Onboard Kegs
							</NavigationItem>
						</ul>
					</li>
					<li className="navigation-item">
						<a onClick={subMenuClickHandler} aria-expanded="false">
							<FontAwesomeIcon icon={icons.faTint} /> BREWING{' '}
							<FontAwesomeIcon icon={icons.faChevronDown} className="arrow" />
						</a>
						<ul className="sub-menu">
							<NavigationItem
								link="/keg-fill"
								icon={icons.faTint}
								toggle={toggle}
							>
								Fill Kegs
							</NavigationItem>
							{claims && compId !== 'not-set' && (
								<NavigationItem
									link={`/companies/batches`}
									icon={icons.faClipboardList}
									toggle={toggle}
								>
									Manage Batches
								</NavigationItem>
							)}
						</ul>
					</li>
					<li className="navigation-item">
						<a onClick={subMenuClickHandler} aria-expanded="false">
							<FontAwesomeIcon icon={icons.faHistory} /> Stock Management{' '}
							<FontAwesomeIcon icon={icons.faChevronDown} className="arrow" />
						</a>
						<ul className="sub-menu">
							<NavigationItem
								link="/dispatch"
								icon={icons.faTruck}
								toggle={toggle}
							>
								Dispatch Kegs
							</NavigationItem>
							<NavigationItem
								link="/receive"
								icon={icons.faInboxIn}
								toggle={toggle}
							>
								Receive Kegs
							</NavigationItem>
							<NavigationItem
								link="/warehouse-transfer"
								icon={icons.faMapMarkerAlt}
								toggle={toggle}
							>
								Warehouse Transfer
							</NavigationItem>
						</ul>
					</li>
					<NavigationItem
						link="/reports/dash"
						icon={icons.faClipboard}
						toggle={toggle}
					>
						Reports
					</NavigationItem>
					{profile?.role !== 'warehouse' && compId !== 'not-set' && (
						<li className="navigation-item">
							<a onClick={subMenuClickHandler} aria-expanded="false">
								<FontAwesomeIcon icon={icons.faBuilding} /> Brewery Settings{' '}
								<FontAwesomeIcon icon={icons.faChevronDown} className="arrow" />
							</a>
							<ul className="sub-menu">
								<div>
									<NavigationItem
										link={`/companies/users`}
										icon={icons.faUsers}
										toggle={toggle}
									>
										Manage Users
									</NavigationItem>
									<NavigationItem
										link={`/companies/details`}
										icon={icons.faPencil}
										toggle={toggle}
									>
										Edit Information
									</NavigationItem>
									<NavigationItem
										link={`/companies/clients`}
										icon={icons.faAddressBook}
										toggle={toggle}
									>
										Manage Clients
									</NavigationItem>
									<NavigationItem
										link={`/companies/locations`}
										icon={icons.faMapMarkerEdit}
										toggle={toggle}
									>
										Manage Warehouses
									</NavigationItem>
									<NavigationItem
										link={`/companies/partners`}
										icon={icons.faUsers}
										toggle={toggle}
									>
										Manage Partners
									</NavigationItem>
									<NavigationItem
										link={`/companies/brands`}
										icon={icons.faBeer}
										toggle={toggle}
									>
										Manage Brands
									</NavigationItem>
									<NavigationItem
										link={`/companies/batches`}
										icon={icons.faTags}
										toggle={toggle}
									>
										Manage Batches
									</NavigationItem>
									<NavigationItem
										link={`/companies/integrations`}
										icon={icons.faSyncAlt}
										toggle={toggle}
									>
										Manage Integrations
									</NavigationItem>
									<NavigationItem
										link={`/companies/notifications`}
										icon={icons.faBell}
										toggle={toggle}
									>
										Manage Notifications
									</NavigationItem>
								</div>
							</ul>
						</li>
					)}
					<NavigationItem link="/help" icon={icons.faLifeRing} toggle={toggle}>
						Help
					</NavigationItem>
				</ul>
			</nav>
		);
	}
}

export default NavigationItems;
