import React, { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Table, Dimmer, Loader, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useKegs } from '../../../app/context/kegs';
import { useLocations } from '../../../app/context/locations';
import SimpleLoader from '../../../app/layout/SimpleLoader';

const TableContent = styled.div`
	margin-top: 1em;
`;

export default function DashboardLocationsStats({ title }) {
	const { locationStats } = useLocations();

	return (
		<div className="widget margin-bottom">
			<div className="widget-table-header">
				<h2>Warehouses</h2>
				<Button size="mini" as={Link} to={`/reports/locations`}>
					Full Report
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
			{locationStats ? (
				locationStats.locationTotals &&
				(locationStats.locationTotals.length !== 0 ? (
					<TableContent>
						<h4>Your own kegs in your warehouses</h4>
						<Table
							basic="very"
							compact="very"
							celled
							collapsing
							className="brands-table"
						>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell />
									<Table.HeaderCell textAlign="center">
										Empty Kegs ({locationStats && locationStats.countEmpty})
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="center">
										Filled Kegs ({locationStats && locationStats.countFilled})
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{locationStats &&
									locationStats.locationTotals.map((location) => (
										<Table.Row key={location.id}>
											<Table.Cell className="label">{location.name}</Table.Cell>
											<Table.Cell
												textAlign="center"
												className="value labelEmptyKegs"
											>
												{location.countEmpty > 0 ? (
													<Popup
														trigger={<p>{location.countEmpty}</p>}
														content={
															<div className="keg-count-label-wrapper">
																{location.empty &&
																	location.empty.map((keg) => (
																		<span
																			className={`${location.empty < 5 &&
																				'cell-span'} keg-count-label`}
																			key={keg}
																		>
																			{keg}
																		</span>
																	))}
															</div>
														}
														inverted
														position="top center"
													/>
												) : (
													location.countEmpty
												)}
											</Table.Cell>
											<Table.Cell
												textAlign="center"
												className="value labelWarehouseKegs"
											>
												{location.countFilled > 0 ? (
													<Popup
														trigger={<p>{location.countFilled}</p>}
														content={
															<div className="keg-count-label-wrapper">
																{location.filled &&
																	location.filled.map((keg) => (
																		<span
																			className={`${location.filled < 5 &&
																				'cell-span'} keg-count-label`}
																			key={keg}
																		>
																			{keg}
																		</span>
																	))}
															</div>
														}
														inverted
														position="top center"
													/>
												) : (
													location.countFilled
												)}
											</Table.Cell>
										</Table.Row>
									))}
							</Table.Body>
						</Table>
					</TableContent>
				) : (
					<div className="no-records-found-illustration-wrapper">
						<img src="/assets/illustrations/empty.svg" alt="Report Empty" />
						<p>No locations found for report...</p>
					</div>
				))
			) : (
				<div className="no-records-found-illustration-wrapper">
					<SimpleLoader color="grey" />
					<p>Loading...</p>
				</div>
			)}
		</div>
	);
}
