import React, { Component, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Table, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useBrands } from '../../../app/context/brands';
import SimpleLoader from '../../../app/layout/SimpleLoader';

export default function DashboardBrandsStats() {
	const { brandStats } = useBrands();
	console.log(brandStats, 'bramds tsa');
	return (
		<div className="widget margin-bottom">
			<div className="widget-table-header">
				<h2>Brands</h2>
				<Button size="mini" as={Link} to={`/reports/brands`}>
					Full Report
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
			{brandStats ? (
				brandStats.brandsTotals &&
				brandStats.brandsTotals.filter(
					(brand) => brand.countDispatched > 0 || brand.countWarehouse > 0
				).length !== 0 ? (
					<Table
						basic="very"
						compact="very"
						celled
						collapsing
						className="brands-table"
					>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell textAlign="center">
									In Warehouse ({brandStats && brandStats.countKegsInWarehouse})
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									In Partner's Warehouse (
									{brandStats && brandStats.countKegsInWarehousePartner})
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">
									Dispatched ({brandStats && brandStats.countKegsDispatched})
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{brandStats &&
								brandStats.brandsTotals
									.filter(
										(brand) =>
											brand.countDispatched > 0 || brand.countWarehouse > 0
									)
									.map((brand) => (
										<Table.Row key={brand.id}>
											<Table.Cell className="label">{brand.name}</Table.Cell>
											<Table.Cell
												className={`value countWarehouse ${brand.labelWarehouse}`}
											>
												{brand.countWarehouse > 0 ? (
													<Popup
														trigger={<p>{brand.countWarehouse}</p>}
														content={
															<div className="keg-count-label-wrapper">
																{brand.kegsWarehouse &&
																	brand.kegsWarehouse.map((keg) => (
																		<span
																			className={`${brand.countWarehouse < 5 &&
																				'cell-span'} keg-count-label`}
																			key={keg.label}
																		>
																			{keg.label}
																		</span>
																	))}
															</div>
														}
														inverted
														position="top center"
													/>
												) : (
													brand.countWarehouse
												)}
											</Table.Cell>
											<Table.Cell
												className={`value countWarehouse ${brand.labelWarehousePartner}`}
											>
												{brand.countWarehousePartner > 0 ? (
													<Popup
														trigger={<p>{brand.countWarehousePartner}</p>}
														content={
															<div className="keg-count-label-wrapper">
																{brand.kegsWarehousePartner &&
																	brand.kegsWarehousePartner.map((keg) => (
																		<span
																			className={`${brand.countWarehousePartner <
																				5 && 'cell-span'} keg-count-label`}
																			key={keg.label}
																		>
																			{keg.label}
																		</span>
																	))}
															</div>
														}
														inverted
														position="top center"
													/>
												) : (
													brand.countWarehousePartner
												)}
											</Table.Cell>
											<Table.Cell
												className={`value countDispatched ${brand.labelDispatched}`}
											>
												{brand.countDispatched > 0 ? (
													<Popup
														trigger={<p>{brand.countDispatched}</p>}
														content={
															<div className="keg-count-label-wrapper">
																{brand.kegsDispatched &&
																	brand.kegsDispatched.map((keg) => (
																		<span
																			className={`${brand.countDispatched < 5 &&
																				'cell-span'} keg-count-label`}
																			key={keg.label}
																		>
																			{keg.label}
																		</span>
																	))}
															</div>
														}
														inverted
														position="top center"
													/>
												) : (
													brand.countDispatched
												)}
											</Table.Cell>
										</Table.Row>
									))}
							{brandStats && brandStats.countKegsWithoutBrands > 0 && (
								<Table.Row>
									<Table.Cell className="label">No Brand</Table.Cell>
									<Table.Cell
										className={`value countWarehouse ${brandStats.countKegsWithoutInWarehouse}`}
									>
										{brandStats.countKegsWithoutInWarehouse}
									</Table.Cell>
									<Table.Cell
										className={`value countWarehouse ${brandStats.countKegsWithoutInWarehouse}`}
									>
										{brandStats.countKegsWithoutInWarehousePartner}
									</Table.Cell>
									<Table.Cell
										className={`value countDispatched ${brandStats.countKegsWithoutDispatched}`}
									>
										{brandStats.countKegsWithoutDispatched}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				) : (
					<div className="no-records-found-illustration-wrapper">
						<img src="/assets/illustrations/empty.svg" alt="Report Empty" />
						<p>No brands found for report...</p>
					</div>
				)
			) : (
				<div className="no-records-found-illustration-wrapper">
					<SimpleLoader color="grey" />
					<p>Fetching brands...</p>
				</div>
			)}
		</div>
	);
}
