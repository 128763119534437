import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { reducer as toastrReducer } from 'react-redux-toastr';
import asyncReducer from '../../app/async/asyncReducer';
import bulkReducer from '../../app/async/bulkReducer';
import dashboardReducer from '../../features/dashboard/dashboardReducer';
import kegsReducer from '../../features/kegs/kegReducer';
import authReducer from '../../features/auth/authReducer';
import loaderReducer from '../layout/DataLoader/loaderReducer';
//import statsReducer from '../../features/stats/statsReducer';

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	form: FormReducer,
	async: asyncReducer,
	bulkProcess: bulkReducer,
	toastr: toastrReducer,
	stats: dashboardReducer,
	kegsClient: kegsReducer,
	claims: authReducer,
	dataLoaded: loaderReducer
	//stats: statsReducer
});

export default rootReducer;
