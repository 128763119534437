import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	DirectionsRenderer,
	GoogleMap,
	Marker,
	MarkerClusterer,
	InfoBox,
	InfoWindow
} from '@react-google-maps/api';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { toastr } from 'react-redux-toastr';
import { useCompany } from '../../app/context/company';
import { useKegs } from '../../app/context/kegs';
import { useKegActions } from '../../app/context/kegs/useKegActions';
import KegToQuickViewDetails from '../kegs/KegQuickView/KegToQuickViewDetails';
import KegInfo from './KegInfo';
import Places from './Places';

// const generateHouses = (position) => {
// 	const _houses = [];
// 	for (let i = 0; i < 100; i++) {
// 		const direction = Math.random() < 0.5 ? -2 : 2;
// 		_houses.push({
// 			lat: position.lat + Math.random() / direction,
// 			lng: position.lng + Math.random() / direction
// 		});
// 	}
// 	return _houses;
// };

const MapPage = () => {
	const [showInfo, setShowInfo] = useState(false);
	const [coords, setCoords] = useState(null);
	const { companyKegs, partnerKegs } = useKegs();
	const [directions, setDirections] = useState();
	const { company } = useCompany();
	const [selectedKeg, setSelectedKeg] = useState(null);
	const { updateKeg, emptyKeg } = useKegActions();

	const empty = (keg) => {
		const message = `Are you sure you want to empty keg ${setSelectedKeg.id}?`;
		toastr.confirm(message, {
			onOk: () =>
				emptyKeg(keg).then(() => {
					const msg = `${setSelectedKeg.id} emptied.`;
					toastr.success('Success', msg);
				})
		});
	};
	const archive = (isArchive, keg) => {
		const message = `Are you sure you want to ${
			isArchive ? 'reactivate' : 'archive'
		} keg ${setSelectedKeg.id}?`;
		toastr.confirm(message, {
			onOk: () =>
				updateKeg(keg, { isArchive: !isArchive }).then(() => {
					const msg = `${setSelectedKeg.id} ${
						isArchive ? 'reactivated' : 'archived'
					}.`;
					toastr.success('Success', msg);
				})
		});
	};

	///////// pinned kegs
	const pinnedKegs = useMemo(() => {
		if (companyKegs && partnerKegs)
			return [...companyKegs, ...partnerKegs]?.filter(
				(keg) => keg.lastPinnedLocation
			);
	}, [companyKegs, partnerKegs]);
	/////////// kegs on clients
	const clientKegs = useMemo(() => {
		if (companyKegs && partnerKegs)
			return [...companyKegs, ...partnerKegs]?.filter((keg) => keg.clientGps);
	}, [companyKegs, partnerKegs]);
	const kegsWithPartner = useMemo(() => {
		if (companyKegs) return [...companyKegs]?.filter((keg) => keg.partnerGps);
	}, [companyKegs]);
	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) =>
				setCoords({
					longitude: position.coords.longitude,
					latitude: position.coords.latitude
				})
			);
		} else {
			console.log('geolocation is not supported in this browser');
		}
	}, []);

	const mapRef = useRef(null);
	const center = useMemo(
		() => ({
			lat: coords?.latitude,
			lng: coords?.longitude
			// lat: +company?.gps_lat,
			// lng: +company?.gps_lng
		}),
		[coords]
	);
	const options = useMemo(
		() => ({
			// mapId: 'b181cac70f27f5e6',
			disableDefaultUI: true,
			clickableIcons: false
		}),
		[]
	);
	const onLoad = useCallback((map) => (mapRef.current = map), []);
	////////// fetch directions
	const fetchDirections = (keg) => {
		if (!center) return;

		// eslint-disable-next-line no-undef
		const service = new google.maps.DirectionsService();
		service.route(
			{
				origin: keg,
				destination: center,
				// eslint-disable-next-line no-undef
				travelMode: google.maps.TravelMode.DRIVING
			},
			(result, status) => {
				if (status === 'OK' && result) {
					setDirections(result);
				}
			}
		);
	};
	// const houses = useMemo(() => generateHouses(center), [center]);
	return (
		<div className="container">
			{company && (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
						className="controls "
					>
						<h1>Search location</h1>
						<Places
							setKegLocation={(position) => {
								// setOffice(position);
								// eslint-disable-next-line no-unused-expressions
								mapRef.current?.panTo(position);
								// mapRef.current.panTo = position;
							}}
						/>
						<div
							style={{ height: '80vh', overflowY: 'auto', margin: '1.5rem 0' }}
						>
							{selectedKeg && <KegToQuickViewDetails keg={selectedKeg} />}
						</div>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<img src="/assets/images/green_MarkerO.png" alt="map icon" />
								<span style={{ margin: '0 0.5rem' }}></span>
								<span>Pinned kegs</span>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '0.8rem'
							}}
						>
							<img src="/assets/images/blue_MarkerO.png" alt="map icon" />
							<span style={{ margin: '0 0.5rem' }}></span>
							<span>Kegs with clients</span>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '0.8rem'
							}}
						>
							<img src="/assets/images/orange_MarkerO.png" alt="map icon" />
							<span style={{ margin: '0 0.5rem' }}></span>
							<span>Kegs with partners</span>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '0.8rem'
							}}
						>
							<img
								src="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
								alt="map icon"
							/>
							<span style={{ margin: '0 0.5rem' }}></span>
							<span>Your location</span>
						</div>
					</div>

					<div className="map">
						{pinnedKegs && (
							<GoogleMap
								zoom={10}
								center={center}
								mapContainerClassName="map-container"
								options={options}
								onLoad={onLoad}
							>
								<>
									{directions && (
										<DirectionsRenderer
											directions={directions}
											options={{
												polylineOptions: {
													zIndex: 50,
													strokeColor: '#1976D2',
													strokeWeight: 5
												}
											}}
										/>
									)}
									<Marker
										position={center}
										icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
									/>
									<MarkerClusterer>
										{(clusterer) =>
											pinnedKegs.map((keg) => (
												<Marker
													// icon={{
													// 	// eslint-disable-next-line no-undef
													// 	labelOrigin: google.maps.Point(25, -10)
													// }}
													// label={keg?.label}
													icon="/assets/images/green_MarkerO.png"
													onMouseDown={() => {
														setSelectedKeg(keg);
														console.log(keg);
													}}
													onMouseOut={() => {
														setShowInfo(false);
													}}
													onMouseOver={() => {
														setShowInfo(true);
													}}
													key={keg?.id}
													position={{
														lat: +keg?.lastPinnedLocation?.latitude,
														lng: +keg?.lastPinnedLocation?.longitude
													}}
													clusterer={clusterer}
													onClick={() => {
														fetchDirections({
															lat: +keg?.lastPinnedLocation?.latitude,
															lng: +keg?.lastPinnedLocation?.longitude
														});
													}}
												>
													{showInfo && (
														<div style={{ backgroundColor: 'red' }}>hdhd</div>
													)}
												</Marker>
											))
										}
									</MarkerClusterer>
									{/* client kegs */}
									<MarkerClusterer>
										{(clusterer) =>
											clientKegs.map((keg) => (
												<Marker
													// icon={{
													// 	// eslint-disable-next-line no-undef
													// 	labelOrigin: google.maps.Point(25, -10)
													// }}
													// label={keg?.label}
													icon="/assets/images/blue_MarkerO.png"
													onMouseDown={() => {
														setSelectedKeg(keg);
														console.log(keg);
													}}
													onMouseOut={() => {
														setShowInfo(false);
													}}
													onMouseOver={() => {
														setShowInfo(true);
													}}
													key={keg?.id}
													position={{
														lat: +keg?.clientGps?.lat,
														lng: +keg?.clientGps?.lng
													}}
													clusterer={clusterer}
													onClick={() => {
														fetchDirections({
															lat: +keg?.clientGps?.lat,
															lng: +keg?.clientGps?.lng
														});
													}}
												>
													{showInfo && (
														<div style={{ backgroundColor: 'red' }}>hdhd</div>
													)}
												</Marker>
											))
										}
									</MarkerClusterer>
									{/* partner kegs */}
									<MarkerClusterer>
										{(clusterer) =>
											kegsWithPartner?.map((keg) => (
												<Marker
													// icon={{
													// 	// eslint-disable-next-line no-undef
													// 	labelOrigin: google.maps.Point(25, -10)
													// }}
													// label={keg?.label}
													icon="/assets/images/orange_MarkerO.png"
													onMouseDown={() => {
														setSelectedKeg(keg);
														console.log(keg);
													}}
													onMouseOut={() => {
														setShowInfo(false);
													}}
													onMouseOver={() => {
														setShowInfo(true);
													}}
													key={keg?.id}
													position={{
														lat: +keg?.partnerGps?.lat,
														lng: +keg?.partnerGps?.lng
													}}
													clusterer={clusterer}
													onClick={() => {
														fetchDirections({
															lat: +keg?.partnerGps?.lat,
															lng: +keg?.partnerGps?.lng
														});
													}}
												>
													{showInfo && (
														<div style={{ backgroundColor: 'red' }}>hdhd</div>
													)}
												</Marker>
											))
										}
									</MarkerClusterer>
								</>
							</GoogleMap>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default MapPage;
