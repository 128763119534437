import React from 'react';

export default ({ display, color }) => {
	const classes = [display, color];
	return (
		<div className={classes.join(' ')}>
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};
