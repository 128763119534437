import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
const LoadingComponent = ({ inverted, loading }) => {
	return (
		<Dimmer
			inverted={inverted}
			active={loading === true || loading === undefined}
		>
			<Loader content="Loading..." />
		</Dimmer>
	);
};

export default LoadingComponent;
