import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, Button, Table } from 'semantic-ui-react';
import { getCompanyOverviewStats, getStockStats } from '../dashboardActions';
import { useLocations } from '../../../app/context/locations';
import { useBrands } from '../../../app/context/brands';
import { useClients } from '../../../app/context/clients';
import { usePartners } from '../../../app/context/partners';
import { useUser, useAuth } from '../../../app/context/auth';
import { getObjFromLink } from '../../../app/context/helpers';
import { useKegs } from '../../../app/context/kegs';
import SimpleLoader from '../../../app/layout/SimpleLoader';

const DashboardOverviewStats = () => {
	const { claims } = useAuth();
	const { locations } = useLocations();
	const { brands } = useBrands();
	const { partners } = usePartners();
	const { clients } = useClients();
	const { stockStats, statusStats } = useKegs();
	const user = useUser();
	const overviewStats = useMemo(() => {
		if (brands && clients && locations && partners) {
			const stats = {};
			stats.locations = locations?.length;
			stats.locationsIds = locations?.map((l) => l.id);
			stats.brands = brands?.length;
			stats.brandsIds = brands?.map((b) => b.id);
			stats.clients = clients?.length;
			stats.clientsIds = clients?.map((c) => c.id);
			stats.partners = partners?.length;
			stats.partnersIds = partners?.map((p) => p.id);
			return stats;
		}
	}, [brands, clients, locations, partners]);

	return (
		<div className="widget">
			<h2>Overview of your Profile</h2>
			<Divider />
			{overviewStats ? (
				<Table
					basic="very"
					compact="very"
					celled
					collapsing
					className="brands-table"
				>
					<Table.Body>
						<Table.Row>
							<Table.Cell className="label">Warehouses</Table.Cell>
							<Table.Cell className="value">
								{overviewStats?.locations}
							</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button
										size="mini"
										as={Link}
										to={`/companies/location-create`}
									>
										Add New
									</Button>
									<Button as={Link} size="mini" to={`/companies/locations`}>
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
						<Table.Row>
							<Table.Cell className="label">Brands</Table.Cell>
							<Table.Cell className="value">{overviewStats?.brands}</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button size="mini" as={Link} to={`/companies/brand-create`}>
										Add New
									</Button>
									<Button as={Link} size="mini" to={`/companies/brands`}>
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
						<Table.Row>
							<Table.Cell className="label">Clients</Table.Cell>
							<Table.Cell className="value">
								{overviewStats?.clients}
							</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button size="mini" as={Link} to={`/companies/client-create`}>
										Add New
									</Button>
									<Button as={Link} size="mini" to={`/companies/clients`}>
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
						<Table.Row>
							<Table.Cell className="label">Total Stock</Table.Cell>
							<Table.Cell className="value">
								{stockStats?.all} <span className="label-small">Used</span>{' '}
								<br />
								{statusStats?.all}{' '}
								<span className="label-small">Allocated</span>
							</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button as={Link} size="mini" to="/kegs-list/all">
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
						<Table.Row>
							<Table.Cell className="label">Stock (Kegs)</Table.Cell>
							<Table.Cell className="value">{stockStats?.kegs}</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button as={Link} size="mini" to="/kegs-list/all">
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
						<Table.Row>
							<Table.Cell className="label">Stock (Gas Bottles)</Table.Cell>
							<Table.Cell className="value">{stockStats?.gas}</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button as={Link} size="mini" to="/kegs-list/all">
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
						<Table.Row>
							<Table.Cell className="label">Stock (Fridges)</Table.Cell>
							<Table.Cell className="value">{stockStats?.fridge}</Table.Cell>
							{user?.role !== 'warehouse' && (
								<Table.Cell className="actions">
									<Button as={Link} size="mini" to="/kegs-list/all">
										View All
									</Button>
								</Table.Cell>
							)}
						</Table.Row>
					</Table.Body>
				</Table>
			) : (
				<div className="no-records-found-illustration-wrapper">
					<SimpleLoader color="grey" />
					<p>Fetching overview...</p>
				</div>
			)}
		</div>
	);
};

export default DashboardOverviewStats;
