import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal, ModalDescription } from 'semantic-ui-react';
import { useAuth } from '../../context/auth';
import { useFirebase } from '../../context/Firebase';
import { transformCollectionSnap } from '../../context/helpers';

const AnounceMent = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const { user, claims, userID } = useAuth();
	const Firebase = useFirebase();
	console.log(modalOpen, 'modal');
	useEffect(() => {
		if (claims && user) {
			Firebase.anounceMentViews().onSnapshot(
				(snap) => {
					const s = transformCollectionSnap(snap);
					const isAlreadyView = s.find(
						(curr) => curr.user_id === claims.user_id
					);
					if (s.length > 0) {
						if (isAlreadyView) {
							setModalOpen(false);
						} else {
							setModalOpen(true);
						}
					}

					console.log(s, 'ss');
				},
				(err) => console.log(err)
			);
			console.log(claims, 'user');
		}
	}, [Firebase, claims, user]);

	const addToViews = () => {
		Firebase.anounceMentViews().add({
			email: user?.email,
			name: user?.displayName,
			user_id: claims.user_id
		});
	};
	return (
		<Modal
			open={modalOpen}
			onClose={() => setModalOpen(false)}
			onOpen={() => {}}
			style={{ borderTop: '4px solid green' }}
			centered={false}
			//trigger={<Button>Scrolling Content Modal</Button>}
		>
			<Modal.Header style={{ display: 'flex', justifyContent: 'center' }}>
				New Features Announcement 🎉
			</Modal.Header>
			<Modal.Content style={{}} image scrolling>
				<Modal.Description>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',

							width: '100%'
						}}
					>
						<p>Hi {user?.displayName} 👋</p>
						<p>
							We are thrilled to announce that KEGTracker has just released a
							huge update. Here's a few highlights of the great new features
							this update includes:
						</p>

						<ul style={{ listStyleType: 'square' }}>
							{/* <li>
								Email notification to your clients/partners when you despatch
								kegs
							</li> */}
							<li>
								Admins can now manage users. You can find this under:{' '}
								<span
									style={{
										color: '#88ADD7'
									}}
								>
									brewery settings -{'>'} manage users
								</span>
							</li>
							<li
								style={{
									margin: '1rem 0'
								}}
							>
								You can now automatically send delivery notification to your
								clients when you dispatch kegs. You can enable this feature
								under:{' '}
								<span
									style={{
										color: '#88ADD7'
									}}
								>
									brewery settings -{'>'} manage notifications{' '}
								</span>
							</li>
							<li>
								We also added the google map where you can view the locations of
								your pinned kegs.
							</li>
						</ul>
						<p>
							Please let us know if you have any feedback,concerns or
							compliments, we'd love to hear from you!
						</p>
					</div>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="Skip"
					labelPosition="right"
					icon="checkmark"
					onClick={() => {
						addToViews();
					}}
					positive
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default AnounceMent;
